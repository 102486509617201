import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import ProjectForm from '../../components/projects/ProjectForm';

// Css
import '../../styles/projects/RenovationPage.css';

// Icons 

import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CreateIcon from '@mui/icons-material/Create';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDownward';


import { Helmet } from 'react-helmet';


class RenovationPage extends React.Component {
  render() {
    return <>
        <Helmet>
            <title>Projet de Rénovation - Chapron-Immo</title>
            <meta name="description" content="Découvrez les différentes étapes de l'accompagnement pour votre projet de rénovation. De l'évaluation initiale à la réalisation des travaux, notre équipe de professionnels vous guide tout au long du processus de rénovation." />
            <meta name="keywords" content="accompagnement, projet de rénovation, étapes de rénovation, évaluation, travaux de rénovation" />
            <meta charset="UTF-8" />
            <meta http-equiv="Content-Language" content="fr" />
        </Helmet>
        <div className="renovation-container">
            <h2>RÉNOVATION</h2>
            <div className="central-block">
                <div className="box1">
                <div className="section1">
                    <h3>Définir vos besoins de rénovation</h3>
                    <p>Identifiez les aspects de votre bien immobilier que vous souhaitez rénover et définissez vos objectifs en termes d'améliorations et de transformations.</p>
                </div>
                <div className="section3">
                    <h3>Collaboration avec l'agent immobilier</h3>
                    <p>Travaillez en étroite collaboration avec votre agent immobilier pour trouver des biens adaptés à la rénovation, évaluer leur potentiel et négocier les conditions d'achat.</p>
                </div>
                </div>
                <div className="box3">
                <div className="section2">
                    <h3>Conception des plans de rénovation</h3>
                    <p>Collaborez avec l'agent immobilier et BR-concept pour concevoir les plans de rénovation, en tenant compte de vos besoins et des contraintes structurelles.</p>
                </div>
                <div className="section4">
                    <h3>Mise en œuvre des travaux de rénovation</h3>
                    <p>Bénéficiez de l'expertise de BR-concept pour réaliser les travaux de rénovation selon les plans établis. Votre agent immobilier coordonnera les démarches administratives et les aspects juridiques liés aux travaux.</p>
                </div>
                </div>
                <div className="vertical-bar"></div>
                <div className="box2">
                <div className="circle circle1"><VisibilityIcon style={{ fontSize: '70px', color: 'white' }} /></div>
                <div className="circle circle2"><VisibilityIcon style={{ fontSize: '70px', color: 'white' }} /></div>
                <div className="circle circle3"><VisibilityIcon style={{ fontSize: '70px', color: 'white' }} /></div>
                <div className="circle circle4"><VisibilityIcon style={{ fontSize: '70px', color: 'white' }} /></div>
                </div>
                <div className="vertical-bar-responsive"></div>
                <div className="box2-responsive">
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft: '7px', marginTop: '7px', fontSize: '55px', color: 'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft: '7px', marginTop: '7px', fontSize: '55px', color: 'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft: '7px', marginTop: '7px', fontSize: '55px', color: 'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft: '7px', marginTop: '7px', fontSize: '55px', color: 'white' }} /></div>
                </div>
            </div>
            <div className="form-block">
                <h3 className="form-title">UN PROJET DE RÉNOVATION ?</h3>
                <h4 className="form-sub-title"><KeyboardArrowDownIcon/> Formulaire de recherche de bien</h4>
                <ProjectForm />
            </div>
        </div>
    </>
  }
}

export default RenovationPage;
