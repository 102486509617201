const GetFavoritesRequest = async (userId) => {
    const url = `https://api.chapron-immo.fr/favorites/user`; 
  
    const favoriteData = {
      userId: userId,
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(favoriteData),
    };
  
    return fetch(url, requestOptions)
      .then(response => response.json().then(data => ({ data, status: response.status })))
      .then(responseData => {
        // console.log('Réponse de l\'API:', responseData);
        return responseData;
      })
      .catch(error => {
        // console.error('Erreur de la requête:', error);
        throw error;
      });
  };
  
  export default GetFavoritesRequest;