import React from 'react';

// Import requests 

import PropertiesRequest from "../../requests/properties/PropertiesRequest"
import PropertyRequest from "../../requests/properties/PropertyRequest"
import RecentPropertiesRequest from "../../requests/properties/RecentPropertiesRequest"
import PropertiesByStandardRequest from "../../requests/properties/PropertiesByStandardRequest"
import GetSectorsRequest from "../../requests/properties/GetSectorsRequest"

const PropertiesContext = React.createContext();

class PropertiesController extends React.Component {

  state = {
    properties: [],
  };


  GetProperties = () => {
    return PropertiesRequest()
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  GetProperty = (id) => {
    return PropertyRequest(id)
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  GetSectors = () => {
    return GetSectorsRequest()
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  GetRecentProperties = () => {
    return RecentPropertiesRequest()
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  

  GetPropertiesByStandard = (secteur, budgetMaximum, typeDeBiens, surfaceMinimum) => {
    return PropertiesByStandardRequest(secteur, budgetMaximum, typeDeBiens, surfaceMinimum)
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  render() {
    const { properties } = this.state;
    const { children } = this.props;

    return (
      <PropertiesContext.Provider
        value={{GetProperty:this.GetProperty,GetRecentProperties: this.GetRecentProperties,GetPropertiesByStandard: this.GetPropertiesByStandard,GetProperties: this.GetProperties,GetSectors:this.GetSectors}}>
        {children}
      </PropertiesContext.Provider>
    );
  }
}

export { PropertiesController, PropertiesContext };
