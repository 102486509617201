import React from 'react';
import '../../styles/projects/RenovationPage.css';
import { Container, Row, Col } from 'react-bootstrap';

import ProjectForm from '../../components/projects/ProjectForm';

// Css
import '../../styles/projects/SellPage.css';


// Icons 

import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CreateIcon from '@mui/icons-material/Create';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDownward';


import { Helmet } from 'react-helmet';



class SellPage extends React.Component {
  render() {
    return <>

        <Helmet>
            <title>Projet de Transaction - Chapron-Immo</title>
            <meta name="description" content="Découvrez les étapes de la mise en œuvre d'un projet de vente et d'un projet d'achat immobilier avec Monsieur Frédéric Chapron. De la recherche du bien à la signature de l'acte, suivez notre processus pour une transaction réussie." />
            <meta name="keywords" content="étapes projet vente achat immobilier, mise en oeuvre projet immobilier, recherche bien, signature acte, Monsieur Frédéric Chapron de chapron-immo" />
            <meta charset="UTF-8" />
            <meta http-equiv="Content-Language" content="fr" />
        </Helmet>
        <div className="sell-container">
            <h2>TRANSACTION</h2>
            
            <div className="central-block">
                <div className="box1">
                <div className={`section1`}>
                    <h3>Recherche et sélection d'un bien immobilier</h3>
                    <p>L'acheteur effectue des recherches pour trouver un bien qui correspond à ses critères (localisation, taille, budget, etc.). L'agent immobilier présente différents biens correspondant aux besoins de l'acheteur.</p>
                </div>
                <div className={`section3`}>
                    <h3>Négociation de l'offre d'achat</h3>
                    <p>L'acheteur exprime son intérêt pour le bien et soumet une offre d'achat à l'agent immobilier. L'agent immobilier transmet l'offre au vendeur et négocie les conditions de vente, y compris le prix, les délais et les modalités.</p>
                </div>
                </div>

                <div className="box3">
                <div className={`section2`}>
                    <h3>Visite du bien immobilier</h3>
                    <p>L'acheteur visite le bien immobilier en compagnie de l'agent immobilier pour évaluer son adéquation avec ses attentes. L'agent immobilier fournit des informations sur le bien, son historique, ses caractéristiques techniques, etc.</p>
                </div>
                <div className={`section4`}>
                    <h3>Signature d'un compromis de vente</h3>
                    <p>Une fois l'accord conclu entre l'acheteur et le vendeur, un compromis de vente est rédigé. L'agent immobilier guide les parties dans la rédaction du compromis, qui spécifie les conditions de la transaction, les délais, les clauses suspensives, etc. L'acheteur verse un dépôt de garantie à la signature du compromis, généralement autour de 10% du prix de vente.</p>
                </div>
                </div>

                <div className="vertical-bar" />
                <div className="box2">
                <div className="circle circle1"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle2"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle3"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle4"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                </div>

                <div className="vertical-bar-responsive"></div>
                <div className="box2-responsive">
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                </div>
            </div>

            <div style={{ marginTop: '150px' }} className="central-block">
                <div className="box1">
                <div className={`section1`}>
                    <h3>Réalisation des formalités juridiques</h3>
                    <p>L'agent immobilier assiste l'acheteur et le vendeur dans l'accomplissement des formalités juridiques, telles que les diagnostics obligatoires, la vérification des documents de propriété, etc. L'agent immobilier peut également coordonner les interventions d'autres professionnels (notaire, expert, etc.) pour assurer la bonne exécution du processus.</p>
                </div>
                <div className={`section3`}>
                    <h3>Obtention du prêt immobilier</h3>
                    <p>Si l'acheteur a besoin d'un prêt immobilier, l'agent immobilier peut l'aider à trouver une solution de financement adaptée en le mettant en relation avec des partenaires financiers. L'agent immobilier peut suivre le dossier de prêt et s'assurer de la bonne avancée du processus.</p>
                </div>
                </div>

                <div className="box3">
                <div className={`section2`}>
                    <h3>Signature de l'acte de vente définitif</h3>
                    <p>Une fois toutes les conditions remplies (y compris l'obtention du prêt le cas échéant), les parties se réunissent chez le notaire pour signer l'acte de vente définitif. L'agent immobilier peut être présent lors de cette étape pour fournir des explications supplémentaires, faciliter la communication et résoudre d'éventuels problèmes de dernière minute.</p>
                </div>
                <div className={`section4`}>
                    <h3>Suivi après la vente</h3>
                    <p>Même après la vente, l'agent immobilier peut continuer à fournir des services tels que la coordination de la remise des clés, le suivi des déménagements, l'aide à la souscription d'assurances, etc. L'agent immobilier reste disponible pour répondre aux questions ou résoudre tout problème éventuel qui survient après la transaction.</p>
                </div>
                </div>

                <div className="vertical-bar" />
                <div className="box2">
                <div className="circle circle1"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle2"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle3"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle4"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                </div>

                <div className="vertical-bar-responsive"></div>
                <div className="box2-responsive">
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                </div>
            </div>

            <div className="form-block">
                <h3 className="form-title">UN PROJET D'ACHAT OU DE VENTE ?</h3>
                <h4 className="form-sub-title"><KeyboardArrowDownIcon/> Formulaire de recherche de bien</h4>
                <ProjectForm/>
            </div>
        </div>

    </>
  }
}

export default SellPage;
