import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../../styles/users/SignupPage.css';
import { AuthContext } from "../../controllers/users/AuthController";
import { useNavigate } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Import validate child 
import SignupFormController from '../../controllers/users/SignupFormController';

// Loading
import LoadingComponent from "../../components/others/LoadingComponent"


import { Helmet } from 'react-helmet';

const SignupPage = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    firstname: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [isLoading, setIsLoading] = useState(false);


  const { Signup } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === 'agreeTerms') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));

    } 
    else
     {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validateFormField(name, value);
  };

  const validateFormField = (fieldName, value) => {
    const signupFormController = new SignupFormController();
    let errorMessage = '';

    switch (fieldName) {
      case 'firstname':
        errorMessage = signupFormController.validateName(value);
        break;
      case 'name':
        errorMessage = signupFormController.validateName(value);
        break;
      case 'email':
        errorMessage = signupFormController.validateEmail(value);
        break;
      case 'password':
        errorMessage = signupFormController.validatePassword(value);
        break;
      case 'confirmPassword':
        errorMessage = signupFormController.validateConfirmPassword(value, formData.password);
        break;
      default:
        break;
    }

    setFormErrors((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { firstname, name, email, password, agreeTerms} = formData;


    const hasErrors = Object.values(formErrors).some((error) => error !== '');

    if (hasErrors || !agreeTerms) {
      return;
    }

    setIsLoading(true);

    Signup(firstname, name, email, password)
      .then((responseData) => {
        setIsLoading(false);

        if (responseData.status && (responseData.data.error || responseData.data.message)) {
          const redirectProps = {
            status: responseData.status,
            error: responseData.data.error,
            message: responseData.data.message,
          };

          navigate('/redirect', { state: redirectProps });
          if (responseData.status === 200) {
            setTimeout(() => {
              navigate('/login');
            }, 3000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) 

    : 
    (
    <>
    <Helmet>
        <title>Inscription - Chapron-Immo</title>
        <meta name="description" content="Inscrivez-vous chez Frédéric Chapron, agence Chapron-Immo, pour accéder à des fonctionnalités exclusives et recevoir les dernières mises à jour sur les nouvelles propriétés disponibles." />
        <meta name="keywords" content="inscription, créer un compte, Frédéric Chapron, Chapron-Immo" />
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Language" content="fr" />
    </Helmet>
    
    <div className="signup-container">
      <form className="signup-form-container" onSubmit={handleSubmit}>
        <h2>Inscription</h2>
        <TextField
          style={{ marginTop: '20px' }}
          label="Prénom"
          type="text"
          name="firstname"
          value={formData.firstname}
          onChange={handleInputChange}
          required
          className="input-field"
          error={!!formErrors.firstname}
          helperText={formErrors.firstname}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          label="Nom"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
          className="input-field"
          error={!!formErrors.name}
          helperText={formErrors.name}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
          className="input-field"
          error={!!formErrors.email}
          helperText={formErrors.email}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          label="Mot de passe"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          required
          className="input-field"
          error={!!formErrors.password}
          helperText={formErrors.password}
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          label="Confirmer le mot de passe"
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          required
          className="input-field"
          error={!!formErrors.confirmPassword}
          helperText={formErrors.confirmPassword}
          inputProps={{ maxLength: 50 }}
        />
        <div style={{ marginTop: '20px' }} className="form-group">
          <FormControlLabel
            control={
              <Checkbox
                name="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleInputChange}
                color="primary"
              />
            }
            label="J'accepte les Conditions générales d'utilisation"
          />
        </div>
        <Button variant="contained" className="gold-button" size="small" type="submit">
          S'inscrire
        </Button>
      </form>
    </div>
    </>
  )}
  </>
  );
};

export default SignupPage;
