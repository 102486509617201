import React from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

// Import Imgs
import House1 from '../../imgs/pictures/VILLA4.jpg';
import House2 from '../../imgs/pictures/VILLA5-ARRIERE-NUIT.jpg';

import Plan1 from '../../imgs/pictures/VILLA3-PDV.png';



import Transaction from '../../imgs/pictures/Transaction.jpg';

// Css
import '../../styles/others/HomePage.css';

import { Row, Col ,Container,Button } from 'react-bootstrap';

// Import Component 
import SortingComponent from '../../components/sorting/SortingComponent';
import LatestPropertiesComponent from "../../components/properties/LatestPropertiesComponent";


// Start Loading 
import WebsiteStartComponent from "../../components/others/WebsiteStartComponent"



// Icon


import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';

import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';


// 

import { Parallax } from 'react-parallax';

import Picture from "../../imgs/pictures/VILLA10-ARRIERE.jpg"




class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [House1, House1, House1, House1],
      currentImage: '',
      loading: true, 
      showImage: false,
    };
  }

  componentDidMount() {
    const hasLoaded = localStorage.getItem('hasLoaded');
    if (hasLoaded) {
      this.setState({ loading: false });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
        localStorage.setItem('hasLoaded', true);
      }, 2000);
    }
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }


  randomizeImage = () => {
    const { images } = this.state;
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    this.setState({ currentImage: randomImage });
  };

  render() {
    
    const {loading, showImage} = this.state;

    if (loading) {
      return <WebsiteStartComponent />;
    }

    const advices = [
      {
        id: 1,
        image: Transaction,
        title: 'Les avantages de l\'achat d\'une maison neuve',
        parts: [
          {
            subtitle: 'Personnalisation selon vos goûts',
            subdescription: 'L\'achat d\'une maison neuve vous offre la possibilité de personnaliser votre espace selon vos préférences. Vous pouvez choisir les finitions, les matériaux et les aménagements intérieurs pour créer une maison qui vous ressemble vraiment.',
            image: Transaction,
          },
          {
            subtitle: 'État impeccable sans réparations',
            subdescription: 'Une maison neuve est exempte des problèmes courants liés à l\'usure et au vieillissement. Vous pouvez vous installer sans soucis immédiats de réparations à effectuer, ce qui vous permet de profiter pleinement de votre nouveau chez-vous dès le début.',
            image: Transaction,
          },
          {
            subtitle: 'Économies d\'énergie à long terme',
            subdescription: 'Les maisons neuves sont conçues en tenant compte de l\'efficacité énergétique, avec des isolations thermiques avancées, des appareils éconergétiques et des systèmes de chauffage et de refroidissement performants. Cela peut vous aider à réduire vos factures d\'énergie et à réaliser des économies à long terme.',
            image: Transaction,
          },
          {
            subtitle: 'Garanties pour une tranquillité d\'esprit',
            subdescription: 'De nombreux constructeurs offrent des garanties sur les maisons neuves, ce qui vous permet d\'aborder votre achat avec une plus grande tranquillité d\'esprit. En cas de problèmes structurels ou de défauts de construction, vous pouvez compter sur ces garanties pour vous protéger.',
            image: Transaction,
          },
        ],
      },
      {
        id: 2,
        image: Transaction,
        title: 'Comment préparer votre maison pour la vente : Astuces pour attirer les acheteurs',
        parts: [
          {
            subtitle: 'Créez une première impression mémorable',
            subdescription: 'L\'entrée de votre maison est la première chose que les acheteurs voient, alors assurez-vous qu\'elle soit accueillante et attrayante. Éliminez les désordres, ajoutez quelques touches de verdure avec des plantes ou des fleurs, et assurez-vous que l\'éclairage est chaleureux et invitant.',
            image: Transaction,
          },
          {
            subtitle: 'Dépersonnalisez et déclutter',
            subdescription: 'Les acheteurs doivent pouvoir se projeter dans votre maison, donc rangez les objets personnels tels que les photos de famille et les souvenirs. Enlevez également les objets encombrants et dégagez les espaces pour créer une sensation de propreté et de grandeur.',
            image: Transaction,
          },
          {
            subtitle: 'Mise en scène astucieuse',
            subdescription: 'Utilisez la mise en scène pour mettre en valeur les meilleurs atouts de votre maison. Utilisez des meubles et des accessoires qui mettent en valeur les espaces, créez des zones fonctionnelles et ajoutez des touches de décoration pour donner une impression de style et de convivialité.',
            image: Transaction,
          },
          {
            subtitle: 'Rénovations et réparations légères',
            subdescription: 'Si votre budget le permet, envisagez de réaliser quelques rénovations ou réparations légères pour donner un aspect frais à votre maison. Cela peut inclure de la peinture fraîche, la réparation de petits défauts, le remplacement des luminaires usés, etc. Ces petites améliorations peuvent avoir un impact significatif sur l\'attrait global de votre propriété.',
            image: Transaction,
          },
          {
            subtitle: 'Mise en valeur du potentiel de rangement',
            subdescription: 'Les acheteurs apprécient les espaces de rangement, alors mettez en valeur le potentiel de stockage de votre maison. Organisez les placards de manière ordonnée, débarrassez-vous des objets inutiles et créez un sentiment d\'espace et de fonctionnalité.',
            image: Transaction,
          },
          {
            subtitle: 'Nettoyage approfondi',
            subdescription: 'Un nettoyage approfondi est essentiel pour donner à votre maison un aspect frais et impeccable. Passez l\'aspirateur dans tous les recoins, lavez les fenêtres, nettoyez les tapis, dépoussiérez les surfaces et assurez-vous que les salles de bains et la cuisine sont particulièrement propres.',
            image: Transaction,
          },
          {
            subtitle: 'Valorisez le potentiel extérieur',
            subdescription: 'N\'oubliez pas de mettre en valeur les espaces extérieurs de votre propriété. Assurez-vous que le jardin est bien entretenu, ajoutez des plantes et des fleurs pour apporter de la couleur, nettoyez les allées et les terrasses, et créez un espace extérieur accueillant où les acheteurs peuvent s\'imaginer se détendre.',
            image: Transaction,
          },
        ],
      },
      {
        id: 3,
        image: Transaction, // Remplacez par le chemin de votre image
        title: 'Les avantages de faire appel à un agent immobilier professionnel et les considérations juridiques lors de transactions immobilières',
        parts: [
          {
            subtitle: 'Avantages de faire appel à un agent immobilier professionnel',
            subdescription: `Que ce soit pour l'achat, la vente ou la location d'une propriété, les transactions immobilières peuvent être complexes sur le plan juridique. Faire appel à un agent immobilier professionnel peut vous offrir de nombreux avantages tout en vous guidant à travers les considérations juridiques essentielles.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Expertise juridique',
            subdescription: `Un agent immobilier professionnel possède une connaissance approfondie des lois et réglementations immobilières. Leur expertise juridique vous assure une transaction conforme aux exigences légales et vous protège contre d'éventuelles complications juridiques.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Accès à un réseau de professionnels',
            subdescription: `Les agents immobiliers professionnels ont établi des liens avec d'autres professionnels de l'immobilier, tels que des avocats spécialisés, des notaires et des experts en évaluation immobilière. Ils peuvent vous recommander les meilleurs experts pour vous assister dans votre transaction et garantir sa légalité.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Documentation et contrats',
            subdescription: `Les transactions immobilières impliquent une multitude de documents et de contrats. Un agent immobilier professionnel vous guide à travers ces documents, s'assurant que vous comprenez pleinement les termes et conditions, et veillant à ce que vos intérêts soient protégés.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Négociation et prix équitable',
            subdescription: `Les agents immobiliers professionnels sont des négociateurs expérimentés qui travaillent en votre nom pour obtenir les meilleures conditions de transaction. Leur connaissance approfondie du marché immobilier et leur savoir-faire en matière de négociation vous permettent d'obtenir un prix équitable et des conditions avantageuses.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Considérations juridiques lors de transactions immobilières',
            subdescription: `Il est essentiel de prendre en compte les aspects juridiques lors de transactions immobilières pour assurer une transaction réussie.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Vérification des antécédents et des titres de propriété',
            subdescription: `Avant d'entamer une transaction immobilière, il est crucial de vérifier les antécédents et les titres de propriété de la propriété concernée. Un agent immobilier professionnel effectue les vérifications nécessaires pour s'assurer que la propriété est libre de tout litige ou problème juridique.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Respect des réglementations locales',
            subdescription: `Les transactions immobilières doivent respecter les réglementations locales en vigueur, telles que les règles d'urbanisme, les zonages et les exigences environnementales. Un agent immobilier professionnel vous guide à travers ces réglementations pour éviter tout problème juridique potentiel.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Évaluation des obligations légales',
            subdescription: `Selon le type de transaction immobilière, il peut y avoir des obligations légales spécifiques à respecter. Un agent immobilier professionnel vous informe sur ces obligations et vous aide à les remplir correctement.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
          {
            subtitle: 'Conclusion',
            subdescription: `Faire appel à un agent immobilier professionnel lors de transactions immobilières offre de nombreux avantages, notamment une expertise juridique, un accès à un réseau de professionnels, une gestion des documents et contrats, ainsi qu'une négociation compétente. De plus, les agents immobiliers professionnels veillent aux considérations juridiques essentielles pour assurer une transaction conforme aux exigences légales. Pour une transaction immobilière en toute confiance et conformité juridique, n'hésitez pas à choisir un agent immobilier professionnel qualifié et expérimenté.`,
            image: Transaction, // Remplacez par le chemin de votre image
          },
        ],
      },
      {
        id: 4,
        image: Transaction,
        title: "Les tendances en matière de design d'intérieur pour les maisons contemporaines",
        parts: [
          {
            subtitle: "Matériaux durables et écologiques",
            subdescription: "Le design d'intérieur contemporain met l'accent sur l'utilisation de matériaux durables et écologiques. Les propriétaires recherchent des matériaux tels que le bois recyclé, le bambou, le liège et d'autres options respectueuses de l'environnement pour créer des espaces à la fois esthétiques et durables.",
            image: Transaction,
          },
          {
            subtitle: "Espaces de vie ouverts",
            subdescription: "Les espaces de vie ouverts sont devenus une tendance majeure dans le design d'intérieur contemporain. Les murs sont abattus pour créer des espaces fluides et multifonctionnels, favorisant ainsi la convivialité et la flexibilité.",
            image: Transaction,
          },
          {
            subtitle: "Technologies intégrées",
            subdescription: "Les maisons contemporaines intègrent de plus en plus les technologies modernes. Des systèmes de domotique avancés aux appareils connectés, les propriétaires cherchent à rendre leur maison plus intelligente et plus pratique.",
            image: Transaction,
          },
          {
            subtitle: "Design minimaliste",
            subdescription: "Le design minimaliste est une tendance dominante dans les maisons contemporaines. Les lignes épurées, les espaces dégagés et les couleurs neutres créent une ambiance calme et apaisante, mettant en valeur les éléments essentiels du design.",
            image: Transaction,
          },
        ],
      },
      {
        id: 5,
        image: Transaction,
        title: "Les prêts hypothécaires : Comment obtenir le meilleur taux et les meilleures conditions",
        parts: [
          {
            subtitle: "Comprendre les options de prêts hypothécaires",
            subdescription: "Il existe différentes options de prêts hypothécaires, telles que les prêts à taux fixe, les prêts à taux variable et les prêts à taux ajustable. Il est essentiel de comprendre chaque option et ses implications avant de faire un choix.",
            image: Transaction,
          },
          {
            subtitle: "Facteurs influençant les taux d'intérêt",
            subdescription: "Les taux d'intérêt des prêts hypothécaires sont influencés par plusieurs facteurs, tels que la santé de l'économie, les politiques monétaires, la solvabilité de l'emprunteur et les conditions du marché immobilier. Il est important de les prendre en compte lors de votre demande de prêt.",
            image: Transaction,
          },
          {
            subtitle: "Conseils pour obtenir le meilleur taux",
            subdescription: "Pour obtenir le meilleur taux hypothécaire possible, vous pouvez prendre des mesures telles que maintenir une bonne cote de crédit, économiser pour un acompte plus important, comparer les offres de prêt de différentes institutions financières et travailler avec un courtier hypothécaire professionnel.",
            image: Transaction,
          },
          {
            subtitle: "Conditions avantageuses du prêt",
            subdescription: "Outre le taux d'intérêt, d'autres conditions du prêt hypothécaire peuvent avoir un impact sur votre expérience d'emprunt. Il est important de prendre en compte des éléments tels que les frais, les clauses de remboursement anticipé et les options de refinancement pour choisir le prêt le plus avantageux pour vous.",
            image: Transaction,
          },
        ],
      }
    ];
  

    const { width } = this.props;

    const isScreenSmall = width === 'xs';

    const imageStyle2 = {
      backgroundImage: `url(${Transaction})`,
      height: isScreenSmall ? '1000px' : '1300px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };

    

    return <>
      <Helmet>
          <title>Chapron Frédéric Immobilier - Chapron-Immo</title>
          <meta name="description" content="Page d'accueil Découvrir MONSIEUR FREDERIC CHAPRON, agence immobilière spécialisée dans la vente de terrains et la construction de maisons neuves, transaction immoiblières de tout types de bien. Faites confiance à Monsieur Frédéric Chapron et son équipe qualifiée pour vos projets immobiliers." />
          <meta name="keywords" content="agence immobilière, Monsieur Frédéric Chapron, vente de terrains, construction de maisons neuves, projets immobiliers" />
          <meta charset="UTF-8" />
          <meta http-equiv="Content-Language" content="fr" />
        </Helmet>
      <div className="home-container">
        <div style={{ position: 'relative'}}>
          {/* <Parallax bgImage={Picture} strength={1000} style={{ height: 1000, marginTop:'-300px',zIndex:-1 }}></Parallax> */}
          <img src={Picture} alt="Background" style={{ height: '1000px', width: '100%', marginTop: '-300px', zIndex: -1, objectFit: 'cover' }} />
          <h1 style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '50px', zIndex: 500 }}>
            CHAPRON Frédéric Immobilier
            <div style={{ width: '30%', height: '5px', backgroundColor: 'gold',  marginTop: '10px', borderRadius: '2px' }}></div>
          </h1>
        </div>



        <div className="redirection-container">
          <div className="box1" style={imageStyle2}>
              <h1 className="title" >ARTHURIMMO</h1>
              <h2 className="sub-title">Transaction <KeyboardDoubleArrowDownOutlinedIcon/></h2>
              <p className="description">fort de 25 ans d'expérience dans le domaine de la transaction immobilière. Ma solide expertise me permet d'accompagner efficacement les particuliers et les professionnels dans leurs projets d'achat et de vente de biens immobiliers. Travaillant avec différents types de biens immobiliers, principalement sur les maisons et terrains à bâtir, je suis également compétent pour traiter d'autres types de biens tels que des appartements, des bureaux, des fonds de commerce. Au-delà de ses services de transaction, Frédéric Chapron offre un suivi et un accompagnement continu à ses clients même après la vente ou l'achat d'un bien immobilier pour  les soutenir dans leur parcours immobilier. </p>
              <div className="button-group">
                <Link to="/achat" className="button-box">Acheter <ShortcutOutlinedIcon/></Link>
                <Link to="/vente" className="button-box">Vendre <ShortcutOutlinedIcon/></Link>
              </div>
          </div>
          

          <div className="box2">
              <h1 className="title" >BR-CONCEPT</h1>
              <div className="box" >
                <div className="sub-box">
                  <h3 className="sub-title" >Construction <KeyboardDoubleArrowDownOutlinedIcon/></h3>
                  <p className="description2" >Je collabore avec un constructeur pour faciliter la réalisation de projets de construction neuve. J'aide mes clients à trouver un terrain à bâtir et les accompagner dans toutes les étapes de leur projet de construction. Je m'occupe d'identifier des terrains adaptés aux besoins de mes clients. Une fois le terrain sélectionné, je collabore avec le constructeur et l'architecte pour concevoir les plans de la maison. Je guide mes clients dans le choix des finitions, des matériaux et des autres aspects essentiels de leur projet. </p>
                  <Link  to="/construction" className="button-box2">Construction <ShortcutOutlinedIcon/></Link>
                </div>

                <div className="vertical-bar"></div>

                <div className="sub-box">
                  <h3 className="sub-title" >Rénovation <KeyboardDoubleArrowDownOutlinedIcon/></h3>
                  <p className="description2" >Nous proposons  des services de rénovation en collaboration avec un partenaire qualifié. Nous mettons en place un plan de rénovation adapté, nous veillons à ce que les projets de rénovation se déroulent efficacement et selon les délais convenus et supervisons les travaux afin d'atteindre les objectif de votre projet.</p>
                  <Link to="/renovation" className="button-box2">Rénovation <ShortcutOutlinedIcon/></Link>
                </div>
              </div>

          </div>
        </div>

        

        <Container fluid className="infos-container">
          <Row className="row justify-content-evenly" >
            <Col lg={4} className="box box1" style={{ marginTop: '200px' }}>
              <h2>Recherche, achat de bien immobilier</h2>
              <SortingComponent />
            </Col>
            <Col lg={4} className="box box2" style={{ marginTop: '200px' }}>
              <img src={House1} alt="Maison à construire" className="image3" />
            </Col>
          </Row>
          <Row className="row justify-content-evenly">
            <Col lg={8} className="box box4">
              <img src={Plan1} alt="Plan d'intérieur de maison à construire" className="image1" />
            </Col>
          </Row>
          <Row className="row justify-content-evenly d-flex align-items-center">
            <Col lg={4} className="box box3">
              <h2>Conseils et actualités en immobilier</h2>
              <div className="comments-part">
                {advices.map((advice) => (
                  <div key={advice.id} className="comment-box">
                    <h3 className="comment-title">{advice.title}</h3>
                    
                    <Link key={advice.id} to={`/news/${advice.id}`} state={{ advice }}>
                      <img src={advice.image} alt="Image du conseil ou actualité" className="comment-img" />
                    </Link>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={4} className="box box2">
              <img src={House2} alt="Image de maison à construire" className="image3" />
            </Col>
          </Row>
        </Container>


        {/* Latest Ads */}
        <LatestPropertiesComponent />


      </div>

    </>
  }
}

export default HomePage;


