import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';

import { Alert, AlertTitle } from '@mui/material';

const RedirectComponent = () => {
  const location = useLocation();
  const { state } = location;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!state) {
    return null; // Rendre null si state est indéfini
  }

  const { status, error, message } = state;

  return (
    <div style={{ background: '#fdfaf4' }}>
      {status === 200 ? (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Statut : {status}
          <br />
          Message : {message}
        </Alert>
      ) : (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Statut : {status}
          <br />
          Erreur : {error}
        </Alert>
      )}
    </div>
  );
};

export default RedirectComponent;
