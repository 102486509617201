import React from 'react';
import { Helmet } from 'react-helmet';

const WebsitePlanPage = () => {
  return <>
      <Helmet>
        <title>Plan du site - Chapron-Immo</title>
        <meta name="description" content="Les détails du plan et de l'architecure du site de Chapron-Immo appartenant a CHAPRON Frédéric de l'entreprise MONSIEUR CHAPRON FREDERIC" />
        <meta name="keywords" content="sitemap,plan du site,architecture du site, fonctionnement du site, details du site, redirection sur le site" />
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Language" content="fr" />
      </Helmet>

      <div style={{ width: '100%', display:'flex', flexDirection:'column', marginLeft: '20%', marginTop: '80px', marginBottom: '80px'}}>
        <h1 style={{ textDecoration: 'underline' }}>Plan du site</h1>
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          <li style={{ marginTop: '40px' }}>
            <a href="/home" style={{ textDecoration: 'underline', color: 'inherit' }}>Page d'accueil</a>
          </li>
          <li>
            <a href="/about1" style={{ textDecoration: 'underline', color: 'inherit' }}>Agence</a>
          </li>
          <li>
            <a href="/achat" style={{ textDecoration: 'underline', color: 'inherit' }}>Achat</a>
          </li>
          <li>
            <a href="/vente" style={{ textDecoration: 'underline', color: 'inherit' }}>Vente</a>
          </li>
          <li>
            <a href="/construction" style={{ textDecoration: 'underline', color: 'inherit' }}>Construction</a>
          </li>
          <li>
            <a href="/renovation" style={{ textDecoration: 'underline', color: 'inherit' }}>Rénovation</a>
          </li>
          <li>
            <a href="/login" style={{ textDecoration: 'underline', color: 'inherit' }}>Connexion</a>
          </li>
          <li>
            <a href="/signup" style={{ textDecoration: 'underline', color: 'inherit' }}>Inscription</a>
          </li>
          <li>
            <a href="/profile" style={{ textDecoration: 'underline', color: 'inherit' }}>Profil utilisateur</a>
          </li>
          <li>
            <a href="/legal" style={{ textDecoration: 'underline', color: 'inherit' }}>Informations légales</a>
          </li>
          <li>
            <a href="/cgu" style={{ textDecoration: 'underline', color: 'inherit' }}>Conditions générales d'utilisation</a>
          </li>
          <li>
            <a href="/websiteplan" style={{ textDecoration: 'underline', color: 'inherit' }}>Plan du site</a>
          </li>
        </ul>
      </div>
  </>
};

export default WebsitePlanPage;
