import React from 'react';
import validator from 'validator';

class SignupFormController extends React.Component {
  validateEmail = (email) => {
    if (this.checkForCodeInjection(email)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (!validator.isEmail(email)) {
      return 'Veuillez fournir une adresse e-mail valide.';
    }
    return '';
  };

  validateName = (name) => {
    if (this.checkForCodeInjection(name)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (!name.trim()) return 'Veuillez fournir votre nom.';
    if (/\d/.test(name)) return 'Le nom ne doit pas contenir de chiffres.';
    if (/[!@#$%^&*]/.test(name)) return 'Le nom ne doit pas contenir de caractères spéciaux.';
    return '';
  };

  validatePassword = (password) => {
    if (this.checkForCodeInjection(password)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    let errorMessages = '';
    let state = 0;

    if (!password.trim()) {
      errorMessages += 'Mot de passe requis. ';
      state = 1;
    } else {
      if (password.length < 8) {
        errorMessages += 'Minimum 8 caractères. ';
        state = 1;
      }
      if (!/\d/.test(password)) {
        errorMessages += 'Au moins un chiffre requis. ';
        state = 1;
      }
      if (!/[a-z]/.test(password)) {
        errorMessages += 'Au moins une lettre minuscule requise. ';
        state = 1;
      }
      if (!/[A-Z]/.test(password)) {
        errorMessages += 'Au moins une lettre majuscule requise. ';
        state = 1;
      }
      if (!/[!@#$%^&*]/.test(password)) {
        errorMessages += 'Au moins un caractère spécial requis. ';
        state = 1;
      }
    }

    if (state === 0) {
      return '';
    }

    errorMessages = 'Élément(s) requis: ' + errorMessages.trim();
    return errorMessages.trim();
  };

  validateConfirmPassword = (confirmPassword, password) => {
    if (this.checkForCodeInjection(confirmPassword)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (confirmPassword !== password) {
      return 'Les mots de passe ne correspondent pas.';
    }
    return '';
  };

  checkForCodeInjection = (input) => {
    const sqlInjectionPattern = /(?:\b(select|insert|update|delete|create|drop|alter)\b.+?\b(from|into|table|database|index|procedure|function|trigger|view)\b)|(?:\bexec\b\s*[\w\W]+?\bxp_cmdshell\b)/i;
    const xssPattern = /<\s*\/?\s*(script|iframe|object|embed|img|svg|style|link|frame|frameset|html|body|meta|xml|input|textarea|button|select|option|form|applet|base|basefont|canvas|caption|center|col|colgroup|dd|del|dir|div|dl|dt|em|fieldset|font|head|h[1-6]|hr|html|ins|label|legend|li|map|menu|ol|optgroup|option|p|pre|q|s|samp|small|span|strike|strong|sub|sup|table|tbody|td|textarea|tfoot|th|thead|title|tr|tt|u|ul)\b/i;
    const additionalPatterns = [
      /on\w+\s*=\s*".*?"/i, // Attaque d'événement JavaScript inline
      /style\s*=\s*".*?"/i, // Attaque de style CSS inline
      /<\s*script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>\s*>/gi, // Attaque de balise <script> complète
      /expression\s*:\s*[^};]+/i, // Attaque d'expression CSS
      /\{\s*behavior\s*:\s*[^};]+/i, // Attaque de comportement CSS
      /javascript:/i, // Attaque de lien href avec le préfixe "javascript:"
      /data:[^\/]+\/[^;]+;base64,/i, // Attaque d'URL de données avec des données base64
      /<!--[\s\S]*?-->/g, // Attaque de commentaire HTML
      /\bdocument\b/i, // Attaque avec le mot-clé "document"
      /\bwindow\b/i, // Attaque avec le mot-clé "window"
    ];

    if (sqlInjectionPattern.test(input) || xssPattern.test(input) || additionalPatterns.some(pattern => pattern.test(input))) {
      return true;
    }

    return false;
  };

  render() {
    return null;
  }
}

export default SignupFormController;
