import React from 'react';
import '../../styles/others/WebsiteStartComponent.css';

// Import img
import Logo from '../../imgs/logos/navbar_logo.png';

const WebsiteStartComponent = () => {
  return (
    <div className="website-start">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
      </div>
    </div>
  );
};

export default WebsiteStartComponent;
