import React from 'react';
import { Button } from 'react-bootstrap';

// Component
import PropertiesMapComponent from "../../components/properties/PropertiesMapComponent"

import InlineSortingComponent from "../../components/sorting/InlineSortingComponent"

// Css
import '../../styles/properties/PropertiesPage.css';

// Context Controller
import { PropertiesContext } from "../../controllers/properties/PropertiesController";
import LoadingComponent from "../../components/others/LoadingComponent";



import { Helmet } from 'react-helmet';


class PropertiesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      Loading: true,
    };
  }

  componentDidMount() {

    window.scrollTo(0, 0);
    
    const queryParams = new URLSearchParams(window.location.search);
    const secteur = queryParams.get('secteur');
    const budget = queryParams.get('budget');
    const typeBien = queryParams.get('types')?.split(',') || [];
    const minSurface = queryParams.get('surface');

    if (secteur && budget && typeBien && minSurface) {
      setTimeout(() => {
        this.fetchPropertiesByStandard(secteur, budget, typeBien, minSurface);
      }, 1000); 
    } else {
      setTimeout(() => {
        this.fetchAllProperties();
      }, 1000); 
    }
  }

  fetchPropertiesByStandard = (secteur, budget, typeBien, minSurface) => {
    const { GetPropertiesByStandard } = this.context;

    this.setState({ Loading: true });

    GetPropertiesByStandard(secteur, budget, typeBien, minSurface)
      .then(responseData => {
        this.setState({ properties: responseData.data.properties, Loading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ Loading: false });
      });
  };

  fetchAllProperties = () => {
    const { GetProperties } = this.context;

    this.setState({ Loading: true });

    GetProperties()
      .then(responseData => {
        this.setState({ properties: responseData.data.properties, Loading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ Loading: false });
      });
  };

  render() {
    const { properties, Loading } = this.state;

    if (Loading) {
      return <LoadingComponent />;
    }

    return (
      <>
        <Helmet>
          <title>Annonces propriétés à vendre - Chapron-Immo</title>
          <meta name="description" content="Découvrez nos annonces immobilières pour la vente de maisons et terrains par l'agence Monsieur Chapron Frédéric. Trouvez votre prochaine propriété à acheter dans notre sélection d'annonces." />
          <meta name="keywords" content="annonces immobilières, propriétés à vendre, Monsieur Chapron Frédéric, vente maisons, vente terrains" />
          <meta charset="UTF-8" />
          <meta http-equiv="Content-Language" content="fr" />
        </Helmet>
        <div className="properties-page-container">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InlineSortingComponent />
          </div>
          <h3 className="title">Bien trouvés : {properties.length}</h3>
          <PropertiesMapComponent properties={properties} />
        </div>
      </>
    );
  }
}

PropertiesPage.contextType = PropertiesContext;

export default PropertiesPage;
