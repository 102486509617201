import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Dropdown, Button } from 'react-bootstrap';
import Logo from '../../imgs/logos/navbar_logo.png';
import '../../styles/others/NavbarComponent.css';
import { AuthContext } from '../../controllers/users/AuthController';

import { useLocation } from 'react-router-dom';


// ICONS 

import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import LocalPhoneIcon from '@material-ui/icons/LocalPhoneOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';



const NavbarComponent = () => {
  const { Logout } = React.useContext(AuthContext);

  const userToken = localStorage.getItem('token');

  const handlePhoneCall = () => {
    window.location.href = `tel:0660972907`;
  };

  const handleLogout = () => {
    Logout();
  };

  const location = useLocation();

  return (
    <div className="nav-header-container">
      <Navbar className="header justify-content-end " expand="lg">
        <Nav.Link as={Link} className="header-links" to="/about1">AGENCE<OtherHousesOutlinedIcon style={{ marginLeft: '4px',marginBottom:'3px' }}/></Nav.Link>
        <Nav.Link
          as={Link}
          className="header-links"
          to={userToken ? "/profile" : "/login"}
        >
          ESPACE CLIENT <AccountCircleIcon style={{ marginLeft: '0px',marginBottom:'3px' }}/>
        </Nav.Link>
        {userToken && (
          <Nav.Link className="header-links" onClick={handleLogout}>
            SE DECONNECTER <LogoutIcon style={{ marginLeft: '0px',marginBottom:'3px' }}/>
          </Nav.Link>
        )}
        <Nav.Link className="header-links" onClick={handlePhoneCall}>
          0660972907 <LocalPhoneIcon style={{ marginLeft: '0px',marginBottom:'3px' }}/>
        </Nav.Link>
        
        <div className="right-link"></div>
      </Navbar>

      <Navbar expand="lg" className="navbar-elements">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="navbar-links">
            <Nav.Link className="" as={Link} to="/achat">
              <span>ACHAT</span>
            </Nav.Link>
            <Nav.Link className="right-link" as={Link} to="/vente">
              <span>VENTE</span>
            </Nav.Link>
            <Nav.Link className="right-link" as={Link} to="/renovation">
              <span>RENOVATION</span>
            </Nav.Link>
            <Nav.Link className="right-link" as={Link} to="/construction">
              <span>CONSTRUCTION NEUVE</span>
            </Nav.Link>
            <div className="right-link"></div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Link to="/home">
        <img style={{ backgroundColor: location.pathname === '/' || location.pathname === '/home' ? 'transparent' : 'white', left: location.pathname === '/' || location.pathname === '/home' ? '0.5%' : '0' }} className="logo" src={Logo} alt="Logo du site internet"  />
      </Link>
    </div>
  ); 
};

export default NavbarComponent;
