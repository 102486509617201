import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class LegalsPage extends React.Component {
  render() {
    return <>
      <Helmet>
        <title>Informations Légales - Chapron-Immo</title>
        <meta name="description" content="Consultez les informations légales de Chapron-Immo. Informations sur l'agence, mentions légales, politique de confidentialité et conditions d'utilisation." />
        <meta name="keywords" content="informations légales, mentions légales, politique de confidentialité, conditions d'utilisation, Chapron-Immo" />
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Language" content="fr" />
      </Helmet>
      <div style={{ width: '60%', margin: '0 auto', marginTop: '80px' }}>
        <h1 style={{ textDecoration: 'underline' }}>Informations Légales</h1>
        <div style={{ marginTop: '80px' }}>
          <h2>Mentions légales</h2>
          <p style={{ marginTop: '20px' }}>Raison sociale : MONSIEUR FREDERIC CHAPRON</p>
          <p>Forme juridique : Entrepreneur individuel</p>
          <p>SIRET : 45339274800027</p>
          <p>Siège social : 145 RTE DE MILLERY, 69700 MONTAGNY</p>
          <p>N° de TVA intracommunautaire : FR24453392748</p>
        </div>
        <div style={{ marginTop: '40px' }}>
          <h2>Conditions générales d'utilisation et politique de confidentialité</h2>
          <p style={{ marginTop: '20px' }}><Link to="/cgu" style={{ color: 'black', textDecoration: 'underline' }}>Conditions générales d'utilisation</Link></p>
        </div>
        <div style={{ marginTop: '40px' }}>
          <h2>Propriété intellectuelle</h2>
          <p style={{ marginTop: '20px' }}>Tous les contenus de ce site web, y compris les textes, les images, les vidéos, les logos, sont la propriété exclusive de MONSIEUR FREDERIC CHAPRON et sont protégés par le droit de la propriété intellectuelle en France.</p>
        </div>
        <div style={{ marginTop: '40px', marginBottom: '150px' }}>
          <h2>Responsabilité</h2>
          <p style={{ marginTop: '20px' }}>Nous nous efforçons de fournir des informations précises et à jour sur notre site web, mais nous n'assumons aucune responsabilité quant à l'exactitude, l'exhaustivité ou la pertinence des informations fournies.</p>
        </div>
      </div>
    </>
  }
}

export default LegalsPage;
