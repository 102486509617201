import React from 'react';

import ProjectForm from '../../components/projects/ProjectForm';

// Css
import '../../styles/projects/NewConstructionPage.css';

// Icons 

import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CreateIcon from '@mui/icons-material/Create';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DescriptionIcon from '@mui/icons-material/Description';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDownward';


import { Helmet } from 'react-helmet';


class NewConstructionPage extends React.Component {
  render() {
    return <>
        <Helmet>
            <title>Projet de Maison Neuve - Chapron-Immo</title>
            <meta name="description" content="Découvrez les différentes étapes de l'accompagnement pour votre projet de construction d'une maison neuve. De la conception à la remise des clés, notre équipe vous guide à chaque étape du processus." />
            <meta name="keywords" content="accompagnement, projet construction, maison neuve, étapes de construction, conception, remise des clés" />
            <meta charset="UTF-8" />
            <meta http-equiv="Content-Language" content="fr" />
        </Helmet>
        <div className="new-construction-container">
            <h2>CONSTRUCTION NEUVE</h2>
            <div className="central-block">
                <div className="box1">
                <div className="section1">
                    <h3>Définir vos besoins et votre budget</h3>
                    <p>Déterminez le nombre de chambres, l'espace de vie et le style architectural souhaités. Établissez un budget réaliste en prenant en compte les coûts de terrain, de construction et les matériaux.</p>
                </div>
                <div className="section3">
                    <h3>Recherche du terrain</h3>
                    <p>Collaborez avec votre agent immobilier pour trouver un terrain adapté à votre projet. Prenez en compte des critères tels que l'emplacement, la taille du terrain, l'accessibilité, les services publics disponibles et les restrictions de construction.</p>
                </div>
                </div>

                <div className="box3">
                <div className="section2">
                    <h3>Établissement des spécifications</h3>
                    <p>Travaillez en collaboration avec votre agent immobilier pour établir les spécifications de votre maison en termes de design, de fonctionnalités et de matériaux. Votre agent immobilier facilitera la communication avec l'architecte et le constructeur de maisons BR-concept.</p>
                </div>
                <div className="section4">
                    <h3>Conception et construction</h3>
                    <p>L'architecte concevra les plans de votre maison en tenant compte de vos spécifications. Ensuite, le constructeur de maisons BR-concept sera responsable de la construction. Votre agent immobilier coordonnera les étapes de construction et veillera au respect des normes et des délais.</p>
                </div>
                </div>

                <div className="vertical-bar" />
                <div className="box2">
                <div className="circle circle1"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle2"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle3"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                <div className="circle circle4"><VisibilityIcon style={{ fontSize: '70px',color:'white' }} /></div>
                </div>

                <div className="vertical-bar-responsive"></div>
                <div className="box2-responsive">
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                <div className="circle-responsive"><VisibilityIcon style={{ marginLeft:'7px',marginTop:'7px' ,fontSize: '55px',color:'white' }} /></div>
                </div>

            </div>

            <div className="form-block">
                <h3 className="form-title">UN PROJET DE CONSTRUCTION ?</h3>
                <h4 className="form-sub-title"><KeyboardArrowDownIcon/> Formulaire de recherche de bien</h4>
                <ProjectForm />
            </div>
            </div>
    </>
  }
}

export default NewConstructionPage;
