







// Finir tout les styles 
// Faire les textes 







// Faire le SEO avec les alt a prévoir pour chaque image 




// mettre le système de break line pour description bien 



// Conditioon maison a construire si titre = maison a construire 




import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { Helmet } from 'react-helmet';


// Components

import NavbarComponent from "./components/others/NavbarComponent";
import NavbarComponentMobile from "./components/others/NavbarComponentMobile";
import FooterComponent from "./components/others/FooterComponent";


// Others

import HomePage from "./pages/others/HomePage";
import AboutPage1 from "./pages/others/AboutPage1";
import NewsPage from "./pages/others/NewsPage";

import WebsitePlanPage from "./pages/others/WebsitePlanPage";

// Projects

import NewConstructionPage from "./pages/projects/NewConstructionPage";
import RenovationPage from "./pages/projects/RenovationPage";
import SellPage from "./pages/projects/SellPage";

// Users
import LoginPage from "./pages/users/LoginPage";
import SignupPage from "./pages/users/SignupPage";
import ProfilePage from "./pages/users/ProfilePage";

// Politics 

import CguPage from "./pages/politics/CguPage";
import LegalPage from "./pages/politics/LegalPage";

// Properties

import PropertiesPage from "./pages/properties/PropertiesPage";
import PropertyPage from "./pages/properties/PropertyPage";

// Redirect Components


import RedirectComponent from "./components/others/RedirectComponent";


// Controller
import { PropertiesController } from './controllers/properties/PropertiesController';
import { AuthController } from './controllers/users/AuthController';

function App() {
  const isLargeScreen = useMediaQuery('(min-width:991px)');

  React.useEffect(() => {
    document.body.style.backgroundColor = '#f2f2f2';
    document.body.style.fontFamily = "'Montserrat', sans-serif";
  }, []);


  
  return (
    <BrowserRouter>
      {/* <Helmet>
        <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self'; style-src 'self'; img-src 'self'; font-src 'self'; connect-src 'self'; frame-src 'self';" />
      </Helmet> */}
      <AuthController>
        <PropertiesController>
          <Routes>
            <Route path="/sitemap.xml" element={<></>} />
            <Route path="/websiteplan" element={<>
              <WebsitePlanPage />
            </>} />
            <Route path="/*" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <HomePage />
            </>} />
            <Route path="/news/:id" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <NewsPage />
            </>} />
            <Route path="/redirect" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <RedirectComponent />
            </>} />
            <Route path="/property/:id" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <PropertyPage />
            </>} />
            <Route path="/legal" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <LegalPage />
            </>} />
            <Route path="/cgu" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <CguPage />
            </>} />
            <Route path="/login" element={<>
              <LoginPage />
            </>} />
            <Route path="/signup" element={<>
              <SignupPage />
            </>} />
            <Route path="/profile" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <ProfilePage />
            </>} />
            <Route path="/home" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <HomePage />
            </>} />
            <Route path="/about1" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <AboutPage1 />
            </>} />
            <Route path="/achat/*" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <PropertiesPage />
            </>} />
            <Route path="/construction" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <NewConstructionPage />
            </>} />
            <Route path="/renovation" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <RenovationPage />
            </>} />
            <Route path="/vente" element={<>
              {isLargeScreen ? <NavbarComponent /> : <NavbarComponentMobile />}
              <SellPage />
            </>} />
            {/* Ajoutez ici les autres routes */}
          </Routes>
          <FooterComponent />
        </PropertiesController>
      </AuthController>
    </BrowserRouter>
  );
}

export default App;
