const RecentPropertiesRequest = async () => {
    const url = 'https://api.chapron-immo.fr/properties/recentProperties';

  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    return fetch(url, requestOptions)
      .then(response => response.json().then(data => ({ data, status: response.status })))
      .then(responseData => {
          return responseData; 
      })
      .catch(error => {
          // console.error('Erreur de la requête:', error);
          throw error; 
      });
  };
  
  export default RecentPropertiesRequest;