import React from 'react';
import { Container } from 'react-bootstrap';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';



// Component
import PropertiesMapComponent from "./PropertiesMapComponent"



// Css
import '../../styles/properties/LatestPropertiesComponent.css';


// Context Controller
import { PropertiesContext } from "../../controllers/properties/PropertiesController";


import ForwardIcon from '@mui/icons-material/ArrowForward';

class LastestPropertiesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: [],
        };
    }

    componentDidMount() {
        const { GetRecentProperties } = this.context;
        GetRecentProperties()
        .then(responseData => {
            this.setState({ properties: responseData.data.properties });
        })
        .catch(error => {
            throw error;
        });
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.parentData !== this.props.parentData) {
            console.log("Données parent mises à jour");
        }
    }

    render() {
        return (
            <>  
                <Container fluid className="latest-properties-container">
                    <h2 className="title">Nos biens immobiliers les plus récents</h2>
                    <PropertiesMapComponent className="properties" properties={this.state.properties}/>
                    <Button variant="contained" className="gold-button" size="small" component={Link} to="/achat">Voir l'ensemble de nos Biens <ForwardIcon/></Button>
                </Container>
            </>
        );
    }
}

LastestPropertiesComponent.contextType = PropertiesContext;

export default LastestPropertiesComponent;
