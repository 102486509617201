const PropertiesByStandardRequest = async (secteur, budgetMaximum, typeDeBiens, surfaceMinimum) => {
    const url = 'https://api.chapron-immo.fr/properties/byStandardProperties';

    console.log(secteur, budgetMaximum, typeDeBiens, surfaceMinimum)
  
    const requestData = {
      secteur: secteur,
      budgetMaximum: budgetMaximum,
      typeDeBiens: typeDeBiens,
      surfaceMinimum: surfaceMinimum
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    };
  
    return fetch(url, requestOptions)
      .then(response => response.json().then(data => ({ data, status: response.status })))
      .then(responseData => {
        console.log(responseData.data)
        return responseData;
      })
      .catch(error => {
        // console.error('Erreur de la requête:', error);
        throw error;
      });
  };
  
  export default PropertiesByStandardRequest;