import React from 'react';
import { Helmet } from 'react-helmet';

// Boostrap
import { Container, Row, Col } from 'react-bootstrap';

import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

// Imgs
import pp from '../../imgs/pictures/profilepicture.png';
import FC from '../../imgs/pictures/FC.jpg';
import Rivs from '../../imgs/pictures/rivs.png';



// Css
import '../../styles/others/AboutPage1.css';


// Pdf 

import hono1 from "../../files/hono1.pdf"
import hono2 from "../../files/hono2.pdf"
import DownloadIcon from '@mui/icons-material/Download';


class AboutPage extends React.Component {


  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openPDFs = () => {
    window.open(hono2, '_blank');
  };


  render() {
    const team = [
      {
        id: 1,
        image: FC,
        description: 'Frédéric CHAPRON, Commercial',
      },
      {
        id: 2,
        image: pp,
        description: 'Ludovic DUBOIS-PAGNON, Gérant',
      },
      {
        id: 3,
        image: pp,
        description: 'Fabienne DUBOIS-PAGNON, Gérant',
      },
      {
        id: 4,
        image: pp,
        description: 'Hervé VISOCCHI, Commercial',
      },
      {
        id: 5,
        image: pp,
        description: 'Anaïs DHAINAUT, Assistante',
      },
    ];

    

    return <>
      <Helmet>
        <title>Présentation de l'agence - Chapron-Immo</title>
        <meta name="description" content="Découvrez l'agence immobilière de Monsieur Frédéric Chapron. Informations sur notre agence, notre expertise, nos valeurs et notre engagement à vous accompagner dans vos projets immobiliers." />
        <meta name="keywords" content="agence immobilière, Monsieur Frédéric Chapron, présentation, expertise, valeurs, accompagnement, projets immobiliers" />
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Language" content="fr" />
      </Helmet>
      <div style={{ backgroundColor: '#fdfaf4' }}>
      <Container className="text-center about1">
        <Row >
            <Col xl={6} md={12} sm={12} xs={12}>
                <div className="box-intro">
                    <h2 style={{ marginBottom: '60px'}}>Agence Trévoux / Montagny</h2>
                    <img src={Rivs} alt="Image de l'agence" className="intro-img" style={{ height: '600px' }} />
                </div>
            </Col>
            <Col xl={6} md={12} sm={10} xs={10} className="box-infos">
                  <p className="description" >L'agence immobilière R.I.V.S met à votre service sa connaissance du secteur et son expertise pour trouver votre logement et réaliser votre projet immobilier à Trévoux. Comme tous les membres du réseau Arthurimmo.com, l'agence immobilière de Trévoux à la double compétence d'agent immobilier et d'expert immobilier en étant agréée auprès du Centre National de l'Expertise (CNE) pour déterminer précisément la valeur vénale ou/et locative de votre maison ou appartement et peut ainsi garantir à chaque client qui franchit la porte de notre agence immobilière, la certitude de trouver la compétence et les conseils qu'il est en droit d'attendre de la part d'un professionnel de l'immobilier. Notre agence Arthurimmo.com vous propose également un large choix d'annonces immobilières sur Trévoux et sa région. Consultez en ligne nos différents biens en Ain et contactez nos agents & experts immobiliers.</p>
                  <div className="button-group" >
                  <Button variant="contained" className="gold-button" size="small" component={Link} to="/legal">Informations légales</Button>
                  <Button onClick={this.openPDFs} variant="contained" className="gold-button" size="small">Voir les honoraires <DownloadIcon/></Button>
                  <Button variant="contained" className="gold-button" size="small" component={Link} to="/achat">Consulter nos annonces</Button>

                  </div>
                  <div className="more-infos">
                    <p><strong>Téléphone : </strong>0660972907</p>
                    <p><strong>Adresse : </strong> 10 rue du Port, 01600 Trévoux</p>
                  </div>
            </Col>
        </Row>


  

        <h1 style={{ marginBottom: '50px', marginTop: '150px'}}>Notre équipe</h1>
        <Row>
          {team.map((person) => (
            <Col key={person.id} lg={4} md={6} sm={12} xs={12}>
              <div className="box-team">
                <img src={person.image} alt="Image du collaborateur" className="team-img" />
                <p style={{ marginTop: '40px' }}><strong>{person.description}</strong></p>
              </div>
            </Col>
          ))}
        </Row>

        




      </Container>
    </div>
    </>
  }
}

export default AboutPage;
