import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import GoogleMapComponent from '../../components/properties/GoogleMapComponent';
import PropertyForm from '../../components/projects/ProjectForm';
import { useParams } from 'react-router-dom';
import { PropertiesContext } from '../../controllers/properties/PropertiesController';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';

// Pdf
import hono1 from "../../files/hono1.pdf"
import hono2 from "../../files/hono2.pdf"
import DownloadIcon from '@mui/icons-material/Download';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useMediaQuery } from '@mui/material';


import { Helmet } from 'react-helmet';


// import loading

import LoadingComponent from '../../components/others/LoadingComponent';

// Css
import "../../styles/properties/PropertyPage.css";

function PropertyPage() {
  const { id } = useParams();
  const { GetProperty } = useContext(PropertiesContext);
  const [property, setProperty] = useState(null);
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);

  const [fullscreenImage, setFullscreenImage] = useState(null);

  const isSmallerScreen = useMediaQuery('(max-width:768px)');

  const openFullscreen = (imageUrl) => {
    if (!isSmallerScreen) {
      setFullscreenImage(imageUrl);
    }
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }

    setTimeout(() => {
      GetProperty(id)
        .then(responseData => {
          setProperty(responseData.data.property);
          setLoading(false);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération de la propriété:', error);
          setLoading(false);
        });
    }, 1000);
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    scrollToTop();
  }, []);

  const handleToggleTrue = () => {
    setActive(true);
  };

  const handleToggleFalse = () => {
    setActive(false);
  };

  const openPDFs = () => {
    window.open(hono2, '_blank');
  };


  

  
  

  if (loading) {
    return <LoadingComponent></LoadingComponent>
  }

  if (!property) {
    return null;
  }

  const parsedImages = JSON.parse(property.images);

  console.log(parsedImages[0],"ddd")

  return <>
    <Helmet>
      <title>{`${property.type}, ${property.location} - Détails de la propriété`}</title>
      <meta name="description" content={property.description} />
      <meta name="keywords" content={`${property.type}, ${property.location}, propriété à vendre`} />
      <meta charset="UTF-8" />
      <meta http-equiv="Content-Language" content="fr" />
    </Helmet>
    <Container fluid className="property-page">
      <Row className="justify-content-center first-row">
        <Col xs={12} md={10} lg={5} className="box1">
          <Carousel>
            {JSON.parse(property.images).map((imageUrl, index) => (
              <Carousel.Item key={index} onClick={() => openFullscreen(imageUrl)}>
                <img className="image1" src={imageUrl} alt={`Image de la maison ${index + 1}`} />
              </Carousel.Item>
            ))}
          </Carousel>
          <Modal show={!!fullscreenImage} onHide={closeFullscreen} centered>
            <Modal.Body>
              <img className="fullscreen-image" src={fullscreenImage} alt="Fullscreen" />
            </Modal.Body>
          </Modal>
        </Col>
        <Col xs={12} md={10} lg={5} className="box2">
          {property.images.length >= 2 && (
            <>
              <img className="image1" src={parsedImages[parsedImages.length - 2]} alt="Avant dernière Image de la maison" />
              <img className="image2" src={parsedImages[parsedImages.length - 1]} alt="Dernière Image de la maison" />
            </>
          )}
          {property.images.length < 2 && (
            <>
              <img className="image1" src={parsedImages[0]} alt="Première Image de la maison" />
              <img className="image2" src={parsedImages[1]} alt="2ème Image de la maison" />
            </>
          )}
        </Col>
      </Row>


      <div className="banner">
        <Button onClick={handleToggleTrue} variant="contained" className="gold-button" size="small">Informations</Button>
        <Button onClick={handleToggleFalse} variant="contained" className="gold-button" size="small">Cette maison vous intéresse ?</Button>
      </div>

      {active ? (
        <>
          
          <h2 className="property-title">{property.name}</h2>

          <Row className="justify-content-center">
            <Col xs={11} lg={6} >
              <div className="property-description">
                <h2 >Description</h2>
                <h5 style={{ marginTop: '40px' }}>{property.description}</h5>
              </div>
            </Col>
            <div className="vertical-bar">
            </div>
            <Col xs={11} lg={4}>
              <div className="technical-box">
                <h2>Caractéristique</h2>
                {property.type && <p style={{ marginTop: '40px' }}>Type : {property.type}</p>}
                {property.location && <p>Emplacement : {property.location}</p>}
                {property.surfacePrimary && (
                  <p>Surface : {property.surfacePrimary} m²</p>
                )}
                {property.price && <p>Prix : {property.price} €</p>}

                {Object.keys(JSON.parse(property.areas)).map((areaKey, index) => {
                  const areaValues = JSON.parse(property.areas)[areaKey];

                  // Vérifiez si areaValues est un tableau non vide
                  if (!Array.isArray(areaValues) || areaValues.length === 0) {
                    return null;
                  }

                  return (
                    <div key={index}>
                      {areaValues.map((value, subIndex) => (
                        <p key={subIndex}>{areaKey} : {value} m²</p>
                      ))}
                    </div>
                  );
                })}


                <Button onClick={openPDFs} variant="contained" className="gold-button" size="small">Voir les honoraires <DownloadIcon/></Button>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="box4 justify-content-center">
          <Col lg={5}>
            <PropertyForm />
          </Col>
        </Row>
      )}


      <Row className="justify-content-center" style={{ marginBottom: '80px', marginTop: '80px' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '100px', marginTop: '200px' }}>Emplacement géographique <LocationOnIcon fontSize="large" /></h2>
        <GoogleMapComponent latitude={property.latitude} longitude={property.longitude} />
      </Row>

    </Container>
    </>
}

export default PropertyPage;
