const LoginRequest = async (email, password) => {
    const url = 'https://api.chapron-immo.fr/users/login';
  
    const requestData = {
      email: email,
      password: password
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    };

  
    return fetch(url, requestOptions)
      .then(response => response.json().then(data => ({ data, status: response.status })))
      .then(responseData => {
        console.log('Réponse de l\'API:', responseData);
        return responseData; // Vous pouvez retourner les données si nécessaire
      })
      .catch(error => {
        console.error('Erreur de la requête:', error);
        throw error; // Vous pouvez jeter l'erreur si nécessaire
      });
  };
  
  export default LoginRequest;
  