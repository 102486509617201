import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const GoogleMapComponent = ({ latitude, longitude }) => {
  const mapContainerStyle = {
    height: '400px',
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyB33AlpNDt6oWtRLyzydp__k_I40DqL7s4"
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={12}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
