const ProjectFormRequest = async (civilite, prenom, nom, telephone, email, secteur, codePostal, message, budget, surfaceHabitable, surfaceTerrain, natureProjet, typeBien, recevoirEmail) => {
    const url = 'https://api.chapron-immo.fr/projects/send'; 


    console.log(url)

  
    const formData = {
      civilite: civilite,
      prenom: prenom,
      nom: nom,
      telephone: telephone,
      email: email,
      secteur: secteur,
      codePostal: codePostal,
      message: message,
      budget: budget,
      surfaceHabitable: surfaceHabitable,
      surfaceTerrain: surfaceTerrain,
      natureProjet: natureProjet,
      typeBien: typeBien,
      recevoirEmail: recevoirEmail,
    };
  

  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    };
  
    return fetch(url, requestOptions)
      .then(response => response.json().then(data => ({ data, status: response.status })))
      .then(responseData => {
          console.log('Réponse de l\'API:', responseData);
          return responseData; // Vous pouvez retourner les données si nécessaire
      })
      .catch(error => {
          console.error('Erreur de la requête:', error);
          throw error; // Vous pouvez jeter l'erreur si nécessaire
      });
  };
  
  export default ProjectFormRequest;
  