import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import "../../styles/others/NewsPage.css"

const NewsPage = () => {
    const location = useLocation();
    const { advice } = location.state || {};


    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const isMobile = useMediaQuery('(max-width: 728px)');
  
    if (!advice) {
        return <div>No advice information available.</div>;
    }
    
  
    return <>
    <Helmet>
      <title>{advice.title} - Chapron-Immo</title>
      <meta name="description" content="Découvrez les détails de l'actualité et des conseils en immobilier de Chapron-Immo. Informations détaillées, photos et conseils pour vous aider dans vos projets immobiliers." />
      <meta name="keywords" content="actualité immobilière, conseils en immobilier, Chapron-Immo, détails actualité, détails conseils, projets immobiliers" />
      <meta charset="UTF-8" />
      <meta http-equiv="Content-Language" content="fr" />
    </Helmet>
    <Container fluid className="news-container">
      {isMobile ? (
        advice.parts.map((part, index) => (
          <Row key={index}>
            <Col md={4}>
                <h3 className="part-subtitle">{part.subtitle}</h3>
                <p className="part-description">{part.subdescription}</p>
            </Col>
            <Col md={4} className="image">
                <img src={part.image} alt="Part Image" className="part-image" />
            </Col>
          </Row>
        ))
      ) 
      : 
      (
        advice.parts.map((part, index) => (
          <Row key={index} className={index % 2 === 0 ? "even-row justify-content-evenly" : "odd-row justify-content-evenly"}>
            {index % 2 === 0 ? (
              <>
                <Col md={4}>
                  <img src={part.image} alt="Part Image" className="part-image" />
                </Col>
                <Col md={4}>
                  <h3 className="part-subtitle">{part.subtitle}</h3>
                  <p className="part-description">{part.subdescription}</p>
                </Col>
              </>
            ) 
            : 
            (
              <>
                <Col md={4}>
                  <h3 className="part-subtitle">{part.subtitle}</h3>
                  <p className="part-description">{part.subdescription}</p>
                </Col>
                <Col md={4} className="">
                  <img src={part.image} alt="Image de la partie" className="part-image" />
                </Col>
              </>
            )}
          </Row>
        ))
      )}
    </Container>
    </>
};
  
export default NewsPage;
