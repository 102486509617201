import React from 'react';
import validator from 'validator';

class ProjectFormController extends React.Component {
  validatePrenom = (prenom) => {
    if (!prenom.trim()) return 'Veuillez fournir votre prénom.';
    if (!validator.isAlpha(prenom, 'fr-FR', { ignore: ' -' })) {
      return 'Le prénom ne doit contenir que des lettres';
    }
    if (this.checkForCodeInjection(prenom)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  validateNom = (nom) => {
    if (!nom.trim()) return 'Veuillez fournir votre nom.';
    if (!validator.isAlpha(nom, 'fr-FR', { ignore: ' -' })) {
      return 'Le nom ne doit contenir que des lettres';
    }
    if (this.checkForCodeInjection(nom)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  validateEmail = (email) => {
    if (!email.trim()) return 'Veuillez fournir une adresse e-mail.';
    if (!validator.isEmail(email)) return 'Veuillez fournir une adresse e-mail valide.';
    if (this.checkForCodeInjection(email)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  validateTelephone = (telephone) => {
    if (!telephone.trim()) return 'Veuillez fournir votre numéro de téléphone.';
    if (!/^\d{10}$/.test(telephone)) return 'Le numéro de téléphone doit être composé de 10 chiffres.';
    if (this.checkForCodeInjection(telephone)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  validateSecteur = (secteur) => {
    if (!secteur.trim()) return 'Veuillez fournir votre secteur.';
    if (this.checkForCodeInjection(secteur)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  validateCodePostal = (codePostal) => {
    if (!codePostal.trim()) return 'Veuillez fournir votre code postal.';
    if (!/^(F-)?\d{5}$/.test(codePostal)) return 'Veuillez fournir un code postal valide.';
    if (this.checkForCodeInjection(codePostal)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  validateMessage = (message) => {
    if (!message.trim()) return 'Veuillez fournir un message.';
    if (this.checkForCodeInjection(message)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    return '';
  };

  checkForCodeInjection = (input) => {
    const sqlInjectionPattern = /(?:\b(select|insert|update|delete|create|drop|alter)\b.+?\b(from|into|table|database|index|procedure|function|trigger|view)\b)|(?:\bexec\b\s*[\w\W]+?\bxp_cmdshell\b)/i;
    const xssPattern = /<\s*\/?\s*(script|iframe|object|embed|img|svg|style|link|frame|frameset|html|body|meta|xml|input|textarea|button|select|option|form|applet|base|basefont|canvas|caption|center|col|colgroup|dd|del|dir|div|dl|dt|em|fieldset|font|head|h[1-6]|hr|html|ins|label|legend|li|map|menu|ol|optgroup|option|p|pre|q|s|samp|small|span|strike|strong|sub|sup|table|tbody|td|textarea|tfoot|th|thead|title|tr|tt|u|ul)\b/i;
    const additionalPatterns = [
      /on\w+\s*=\s*".*?"/i, // Attaque d'événement JavaScript inline
      /style\s*=\s*".*?"/i, // Attaque de style CSS inline
      /<\s*script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>\s*>/gi, // Attaque de balise <script> complète
      /expression\s*:\s*[^};]+/i, // Attaque d'expression CSS
      /\{\s*behavior\s*:\s*[^};]+/i, // Attaque de comportement CSS
      /javascript:/i, // Attaque de lien href avec le préfixe "javascript:"
      /data:[^\/]+\/[^;]+;base64,/i, // Attaque d'URL de données avec des données base64
      /<!--[\s\S]*?-->/g, // Attaque de commentaire HTML
      /\bdocument\b/i, // Attaque avec le mot-clé "document"
      /\bwindow\b/i, // Attaque avec le mot-clé "window"
    ];
  
    if (sqlInjectionPattern.test(input) || xssPattern.test(input) || additionalPatterns.some(pattern => pattern.test(input))) {
      return true;
    }
  
    return false;
  };
}

export default ProjectFormController;
