import React, { useState, useEffect, useContext } from 'react';
import { FormControlLabel, FormControl, Select, MenuItem, TextField, Checkbox, Button } from '@mui/material';
import SortingFormController from '../../controllers/properties/SortingFormController';
import { PropertiesContext } from '../../controllers/properties/PropertiesController';
import { useNavigate } from 'react-router-dom';

import "../../styles/sorting/InlineSortingComponent.css";

const InlineSortingComponent = () => {
  const { GetSectors } = useContext(PropertiesContext);
  const [formData, setFormData] = useState({ secteur: '', budget: '', typeBien: [], minSurface: '' });
  const [sectorsList, setSectorsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSectors();
  }, []);

  const fetchSectors = () => {
    GetSectors()
      .then(responseData => {
        setSectorsList(responseData.data);
      })
      .catch(error => {
        throw error;
      });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const { typeBien } = formData;

    let updatedValue;

    if (name === 'typeBien') {
      updatedValue = checked ? [...typeBien, value] : typeBien.filter(item => item !== value);
      setFormData(prevState => ({
        ...prevState,
        typeBien: updatedValue,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    validateFormField(name, value);
  };

  const validateFormField = (fieldName, value) => {
    const sortingFormController = new SortingFormController();
    let errorMessage = '';

    switch (fieldName) {
      case 'secteur':
        errorMessage = sortingFormController.validateSecteur(value);
        break;
      case 'budget':
        errorMessage = sortingFormController.validateBudget(value);
        break;
      case 'typeBien':
        errorMessage = sortingFormController.validateTypeBien(value);
        break;
      case 'minSurface':
        errorMessage = sortingFormController.validateMinSurface(value);
        break;
      default:
        break;
    }

    return errorMessage;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { secteur, budget, typeBien, minSurface } = formData;

    if (secteur && budget && typeBien && minSurface) {
      const queryString = `secteur=${secteur}&budget=${budget}&types=${typeBien.join(',')}&surface=${minSurface}`;
      navigate(`/achat/results?${queryString}`);
      document.location.reload();
    }
  };

  return (
    <div className="inline-sorting-container">
      <div>
        <label className="secteur">Secteur :</label>
        <FormControl className="">
          <Select style={{ width: '200px', height: '40px', marginLeft: '10px' }} name="secteur" className="custom-select top-offset" value={formData.secteur} onChange={handleInputChange} required>
            {sectorsList.map((secteurItem, index) => (
              <MenuItem key={index} value={secteurItem}>
                {secteurItem}
              </MenuItem>
            ))}
            <MenuItem value="Tous">
              Tous
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <FormControl className="top-offset" style={{ width: '200px', height: '40px', marginLeft: '10px' }}>
        <TextField placeholder="Budget Maximum" name="budget" value={formData.budget} onChange={handleInputChange} label="" type="number" className="custom-textfield" required />
      </FormControl>

      <div className="checkbox-group top-offset" style={{ marginLeft: '50px' }}>
        <div>Maison<Checkbox name="typeBien" value="Maison" onChange={handleCheckboxChange} required /></div>
        <div>Terrain<Checkbox name="typeBien" value="Terrain" onChange={handleCheckboxChange} required /></div>
        <div>Autre<Checkbox name="typeBien" value="Autre" onChange={handleCheckboxChange} required /></div>
      </div>

      <FormControl className="" style={{ width: '200px', marginLeft: '10px' }}>
        <TextField placeholder="Surface Minimum" name="minSurface" value={formData.minSurface} onChange={handleInputChange} label="" type="number" className="custom-textfield" required />
      </FormControl>

      <div className="search-button top-offset">
        <Button variant="contained" className="gold-button" size="small" onClick={handleSubmit}>
          Rechercher
        </Button>
      </div>
    </div>
  );
};

export default InlineSortingComponent;
