import React from 'react';
import { Helmet } from 'react-helmet';

class CguPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Conditions générales d'utilisation et Politique de confidentialité - Votre entreprise</title>
          <meta name="description" content="Consultez les Conditions Générales d'Utilisation (CGU) et la Politique de Confidentialité de notre site web. Informations sur les termes et conditions régissant l'utilisation du site web, la collecte, l'utilisation et la protection des données personnelles." />
          <meta name="keywords" content="CGU, politique de confidentialité, termes et conditions, utilisation du site web, collecte de données, utilisation des données, protection des données, votre entreprise" />
          <meta charset="UTF-8" />
          <meta http-equiv="Content-Language" content="fr" />
        </Helmet>
        <div style={{ width: '40%', margin: '0 auto', marginTop: '80px'}}>
          <h1 style={{ textDecoration: 'underline' }}>Conditions Générales d'Utilisation et Politique de Confidentialité</h1>
          <div style={{ marginTop: '80px' }}>
            <h2>Collecte et utilisation des données</h2>
            <p>
              Lorsque vous utilisez notre service, nous collectons certaines informations personnelles telles que votre nom, votre adresse e-mail, votre numéro de téléphone, etc. Ces informations sont collectées dans le but de vous fournir les services demandés, de gérer votre compte utilisateur et de vous contacter si nécessaire.
            </p>
          </div>
          <div style={{ marginTop: '40px' }}>
            <h2>Gestion des utilisateurs</h2>
            <p>
              Les informations telles que votre nom, votre adresse e-mail et votre mot de passe sont utilisées pour créer et gérer votre compte utilisateur. Votre mot de passe est stocké de manière sécurisée, cryptée et n'est accessible à personne d'autre que vous.
            </p>
          </div>
          <div style={{ marginTop: '40px' }}>
            <h2>Projets immobiliers</h2>
            <p>
              Lorsque vous soumettez un formulaire de projet immobilier, nous collectons des informations telles que votre nom, votre adresse e-mail, votre numéro de téléphone, le secteur recherché, le code postal, etc. Ces informations sont utilisées pour traiter votre demande de projet immobilier et vous contacter si nécessaire. Les informations sur la surface habitable, la surface du terrain, la nature du projet et le type de bien sont utilisées pour mieux comprendre vos besoins et vous fournir des recommandations personnalisées.
            </p>
          </div>
          <div style={{ marginTop: '40px' }}>
            <h2>Système d'ajout de favoris</h2>
            <p>
              Notre système d'ajout de favoris permet aux utilisateurs de sauvegarder des biens immobiliers qui les intéressent. Lorsque vous utilisez cette fonctionnalité, nous enregistrons l'identifiant du bien immobilier et l'identifiant de l'utilisateur associé. Ces informations sont utilisées pour enregistrer vos préférences et vous fournir un accès facile à vos favoris ultérieurement.
            </p>
          </div>
          <div style={{ marginTop: '40px' }}>
            <h2>Partage des données</h2>
            <p>
            Nous ne partagerons pas vos informations personnelles avec des tiers sans votre consentement explicite, à moins que cela ne soit requis par la loi ou dans le cadre de la fourniture des services demandés. Si vous avez accepté de recevoir des e-mails de la part du propriétaire du site, nous pourrons ensuite vous contacter.
            </p>
          </div>
          <div style={{ marginTop: '40px' }}>
            <h2>Protection des données</h2>
            <p>
              Nous prenons les mesures de sécurité appropriées pour protéger vos données contre tout accès non autorisé ou divulgation. Nous utilisons des technologies de sécurité avancées et mettons en place des mesures de protection des données pour garantir la confidentialité et l'intégrité de vos informations personnelles.
            </p>
          </div>
          <div style={{ marginTop: '40px' }}>
            <h2>Conservation des données</h2>
            <p>
              Nous conservons vos données aussi longtemps que nécessaire pour fournir les services demandés, respecter nos obligations légales et garantir le bon fonctionnement de notre système. Si vous souhaitez supprimer vos données, veuillez nous contacter et nous les effacerons conformément à notre politique de conservation des données.
            </p>
          </div>
          <div style={{ marginTop: '40px', marginBottom:'80px'}}>
            <h2>Modification des conditions d'utilisation et de la politique de confidentialité</h2>
            <p>
              Nous nous réservons le droit de modifier ces conditions d'utilisation et cette politique de confidentialité à tout moment. Toute modification sera publiée sur notre site web et vous serez informé des changements importants par le biais des moyens de communication appropriés.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default CguPage;
