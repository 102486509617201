const SignupRequest  = async (firstname, name, email, password) => {
  const url = 'https://api.chapron-immo.fr/users/signup'; // Remplacez l'URL par celle de votre API

  const userData = {
    firstname: firstname,
    name: name,
    email: email,
    password: password,
  };



  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  };

  return fetch(url,requestOptions)
    .then(response => response.json().then(data => ({ data, status: response.status })))
    .then(responseData => {
        // console.log('Réponse de l\'API:', responseData);
        return responseData; 
    })
    .catch(error => {
        // console.error('Erreur de la requête:', error);
        throw error; 
    });
};

export default SignupRequest;
