import React from 'react';
import validator from 'validator';

class SortingFormController extends React.Component {
  validateSecteur = (secteur) => {
    if (this.checkForCodeInjection(secteur)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (!secteur) {
      return 'Le champ secteur est obligatoire.';
    }
    return '';
  };

  validateBudget = (budget) => {
    if (this.checkForCodeInjection(budget)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (!budget) {
      return 'Le champ budget est obligatoire.';
    }
    if (isNaN(budget)) {
      return 'Veuillez fournir une valeur numérique pour le champ budget.';
    }
    return '';
  };

  validateTypeBien = (typeBien) => {
    if (this.checkForCodeInjection(typeBien)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (!typeBien || typeBien.length === 0) {
      return 'Veuillez sélectionner au moins un type de bien.';
    }
    return '';
  };

  validateMinSurface = (minSurface) => {
    if (this.checkForCodeInjection(minSurface)) {
      return 'Le champ ne doit pas contenir de code ou de balises HTML indésirables.';
    }
    if (!minSurface) {
      return 'Le champ surface minimum est obligatoire.';
    }
    if (isNaN(minSurface)) {
      return 'Veuillez fournir une valeur numérique pour le champ surface minimum.';
    }
    return '';
  };

  checkForCodeInjection = (input) => {
    const sqlInjectionPattern = /(?:\b(select|insert|update|delete|create|drop|alter)\b.+?\b(from|into|table|database|index|procedure|function|trigger|view)\b)|(?:\bexec\b\s*[\w\W]+?\bxp_cmdshell\b)/i;
    const xssPattern = /<\s*\/?\s*(script|iframe|object|embed|img|svg|style|link|frame|frameset|html|body|meta|xml|input|textarea|button|select|option|form|applet|base|basefont|canvas|caption|center|col|colgroup|dd|del|dir|div|dl|dt|em|fieldset|font|head|h[1-6]|hr|html|ins|label|legend|li|map|menu|ol|optgroup|option|p|pre|q|s|samp|small|span|strike|strong|sub|sup|table|tbody|td|textarea|tfoot|th|thead|title|tr|tt|u|ul)\b/i;
    const additionalPatterns = [
      /on\w+\s*=\s*".*?"/i, // Attaque d'événement JavaScript inline
      /style\s*=\s*".*?"/i, // Attaque de style CSS inline
      /<\s*script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>\s*>/gi, // Attaque de balise <script> complète
      /expression\s*:\s*[^};]+/i, // Attaque d'expression CSS
      /\{\s*behavior\s*:\s*[^};]+/i, // Attaque de comportement CSS
      /javascript:/i, // Attaque de lien href avec le préfixe "javascript:"
      /data:[^\/]+\/[^;]+;base64,/i, // Attaque d'URL de données avec des données base64
      /<!--[\s\S]*?-->/g, // Attaque de commentaire HTML
      /\bdocument\b/i, // Attaque avec le mot-clé "document"
      /\bwindow\b/i, // Attaque avec le mot-clé "window"
    ];

    if (sqlInjectionPattern.test(input) || xssPattern.test(input) || additionalPatterns.some(pattern => pattern.test(input))) {
      return true;
    }

    return false;
  };

  render() {
    return null;
  }
}

export default SortingFormController;
