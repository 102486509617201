import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import House1 from '../../imgs/pictures/House1.jpg';
import "../../styles/users/ProfilePage.css";
import { AuthContext } from '../../controllers/users/AuthController';
import GetFavoritesRequest from "../../requests/favorites/GetFavoritesRequest";
import GetFavoritesProperties from "../../requests/properties/GetFavoritesProperties";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

// Loading
import LoadingComponent from "../../components/others/LoadingComponent"

import { Helmet } from 'react-helmet';

class ProfilePage extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      favorites: [],
      favoriteProperties: [],
      isLoading: true,
      user: null
    };
  }

  componentDidMount() {
    const { VerifyToken } = this.context;
    const token = localStorage.getItem('token');
  
    if (token) {
      VerifyToken()
        .then((user) => {
          this.setState({ user });
          return user.userId;
        })
        .then((userId) => {
          return this.getFavorites(userId);
        })
        .then((favorites) => {
          return this.getFavoriteProperties(favorites);
        })
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.error('Erreur lors de la vérification du token :', error);
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: true });
    }
  }
  
  getFavorites(userId) {
    return GetFavoritesRequest(userId)
      .then((responseData) => {
        this.setState({ favorites: responseData.data.favorites });
        return responseData.data.favorites
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des favoris :', error);
      });
  }
  
  getFavoriteProperties(favorites) {
  
    return GetFavoritesProperties(favorites)
      .then((responseData) => {
        if (responseData.status === 200) {
          this.setState({ favoriteProperties: responseData.data.properties });
          return responseData.data.properties
        }
      })
      .catch((error) => {
        console.error('Erreur de la requête :', error);
      });
  }
  

  render() {
    const { user, isLoading, favoriteProperties } = this.state;

    if (isLoading) {
      return <LoadingComponent />;
    }

    const { firstname, name, email } = user;

    return <>
      <Helmet>
        <title>Profil - Chapron-Immo</title>
        <meta name="description" content="Découvrez le profil de Frédéric Chapron, agent immobilier chez Chapron-Immo. Consultez ses informations professionnelles et découvrez ses propriétés disponibles." />
        <meta name="keywords" content="profil, Frédéric Chapron, agent immobilier, Chapron-Immo" />
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Language" content="fr" />
      </Helmet>
      <Container className="profile-page">
        <Row>
          <Col className="user-infos" md={6}>
            <h2>Informations de l'utilisateur</h2>
            <div style={{ marginTop: '50px' }}>
              <strong>Prénom :</strong> {firstname}
            </div >
            <div style={{ marginTop: '20px' }}>
              <strong>Nom :</strong> {name}
            </div>
            <div style={{ marginTop: '20px' }}>
              <strong>Adresse mail :</strong> {email}
            </div>
          </Col>
          <Col md={6} className="second-part">
            <h2>Mes biens ajoutés en favoris</h2>
            <div className="favorites-part">
              {favoriteProperties.map((property, index) => {
                const imagesArray = JSON.parse(property.images);

                return (
                <div key={index} className="property">
                  <Carousel className="custom-carousel" interval={null} pause={true}>
                    {imagesArray.map((imageUrl, index) => (
                      <Carousel.Item key={index}>
                        <div className="image-container">
                          <Link to={`/property/${property.id}`}>
                            <img src={imageUrl} alt="Property" />
                          </Link>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="info">
                    <div className="first-info">
                      <div className="property-type">{property.type}</div>
                      <div className="price">{property.price}</div>
                    </div>
                    <div className="location">{property.location}</div>
                    <div className="features">
                      <div className="feature">
                        <span className="feature-label">Surface :</span> {property.surfacePrimary} m²
                      </div>
                      <div className="feature">
                        <span className="feature-label">Pièces :</span> {property.rooms}
                      </div>
                      <div className="feature">
                        <span className="feature-label">Chambres :</span> {property.bedRooms}
                      </div>
                    </div>
                  </div>
                </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  }
}

export default ProfilePage;
