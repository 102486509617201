import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import '../../styles/others/NavbarComponentMobile.css';
import { AuthContext } from '../../controllers/users/AuthController';



// Logo 


import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import LocalPhoneIcon from '@material-ui/icons/LocalPhoneOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';

class NavbarComponentMobile extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      anchorEl: null
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({
      mobileOpen: !prevState.mobileOpen
    }));
  };

  handleAgencesClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAgencesClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePhoneCall = () => {
    window.location.href = `tel:0660972907`;
  };

  handleLogout = () => {
    const { Logout } = this.context;
    Logout();
  };

  render() {
    const { window } = this.props;
    const { mobileOpen, anchorEl } = this.state;
    const userToken = localStorage.getItem('token');
    const { user } = this.context;
    const isAgencesMenuOpen = Boolean(anchorEl);

    const renderAgencesMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isAgencesMenuOpen}
        onClose={this.handleAgencesClose}
      >
        <MenuItem component={Link} to="/agence1" onClick={this.handleAgencesClose}>Agence 1</MenuItem>
        <MenuItem component={Link} to="/agence2" onClick={this.handleAgencesClose}>Agence 2</MenuItem>
      </Menu>
    );

    const drawer = (
      <Box onClick={this.handleDrawerToggle} className="drawer">
        <Divider />
        <List>
          <ListItem disablePadding>
            {/* <ListItemButton component={Link} to="/agences" className="headButton" onClick={this.handleAgencesClick}>
              <ListItemText primary="AGENCE" />
            </ListItemButton>
          </ListItem> */}
            <ListItemButton component={Link} to="/about1" className="headButton">
              <ListItemText primary="Agence" /> <OtherHousesOutlinedIcon style={{ marginRight: '43px',marginBottom:'0px' }}/>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={userToken ? "/profile" : "/login"} className="headButton">
              <ListItemText primary="Espace Client" /> <AccountCircleIcon style={{ marginLeft: '3px',marginBottom:'0px' }}/>
            </ListItemButton>
          </ListItem>
          {userToken && (
            <ListItem disablePadding>
              <ListItemButton onClick={this.handleLogout} className="headButton">
                <ListItemText primary="Se déconnecter" /> <LogoutIcon style={{ marginLeft: '3px',marginBottom:'0px' }}/>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={this.handlePhoneCall} className="headButton">
              <ListItemText primary="0660972907" /> <LocalPhoneIcon style={{ marginRight: '5px',marginBottom:'0px' }}/>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
      <Box>
        <CssBaseline />
        <AppBar component="nav" className="appBar">
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={this.handleDrawerToggle} className="menuButton">
              <MenuIcon />
            </IconButton>
            <Box className="desktopNav">
              <Button className="navButton" component={Link} to="/home" style={{ color: 'white' }}>Accueil</Button>
              <Button className="navButton" component={Link} to="/achat" style={{ color: 'white' }}>Achat</Button>
              <Button className="navButton" component={Link} to="/vente" style={{ color: 'white' }}>Vente</Button>
              <Button className="navButton" component={Link} to="/renovation" style={{ color: 'white' }}>Rénovation</Button>
              <Button className="navButton" component={Link} to="/construction" style={{ color: 'white' }}>Construction neuve</Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer container={container} variant="temporary" open={mobileOpen} onClose={this.handleDrawerToggle} ModalProps={{ keepMounted: true }} className={`drawerMobile ${mobileOpen ? 'centered' : ''}`}>{drawer}</Drawer>
        {renderAgencesMenu}
      </Box>
    );
  }
}

export default NavbarComponentMobile;
