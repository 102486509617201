import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import '../../styles/others/FooterComponent.css'; 
import { NavLink, Link } from 'react-router-dom';

class FooterComponent extends React.Component {
  render() {
    return (
    <footer className="footer">
    <Container className="footer-el">
      <Row>
        <Col md={6}>
          <h5>Partenaires</h5>
          <ul className="button-list">
            <li><NavLink to="/" className="custom-link">BR-Concept</NavLink></li>
          </ul>
        </Col>
        <Col md={6}>
          <h5>Nos agences</h5>
          <ul className="button-list">
            <li><NavLink to="/about1" className="custom-link">Trévoux / Montagny  </NavLink></li>
          </ul>
        </Col>
        <hr className="footer-line" />
        <Col md={6}>
          <h5>Informations légales</h5>
          <ul className="button-list">
            <li><NavLink to="/legal" className="custom-link">Informations légales</NavLink></li>
            <li><NavLink to="/cgu" className="custom-link">Conditions générales d'utilisation</NavLink></li>
          </ul>
        </Col>
        <Col md={6}>
          <h5>Nous contacter</h5>
          <ul className="button-list">
            <li><NavLink to="/" className="custom-link">0660972907</NavLink></li>
            <li><NavLink to="/" className="custom-link">frederic@chapron-immo.fr</NavLink></li>
          </ul>
        </Col>
      </Row>
      
      <hr className="footer-line" />

      <Row>
        <Col>
          <p className="compagny-right">© 2023 MONSIEUR FREDERIC CHAPRON. Tous droits réservés.</p>
          <Link to="/websiteplan" className="custom-link" style={{ marginLeft: '10%' }}>Plan du site</Link>
        </Col>
      </Row>
    </Container>
  </footer>
  );
  }
};

export default FooterComponent;
