import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../styles/others/LoadingComponent.css';

const Loading = () => (
  
  <div className="loading-container" style={{ background: '#fdfaf4' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress style={{ color: 'black' }} size={40} />
      <span style={{ marginLeft: '20px' }}>Chargement...</span>
    </Box>
  </div>
);

export default Loading;