import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

// Users Requests
import SignupRequest from "../../requests/users/SignupRequest";
import LoginRequest from "../../requests/users/LoginRequest";
import VerifyTokenRequest from "../../requests/users/VerifyTokenRequest";

const AuthContext = React.createContext();

function AuthController(props) {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    showAlert: false,
    alertSeverity: 'error',
    alertMessage: '',
  });

  const handleAlertClose = () => {
    setState(prevState => ({ ...prevState, showAlert: false }));
  };

  const VerifyToken = () => {
    const token = localStorage.getItem('token');


    if (token) {
      return VerifyTokenRequest(token)
        .then(responseData => {
          if (responseData.status === 200) {
            setState(prevState => ({
              ...prevState,
              showAlert: true,
              alertSeverity: 'success',
              alertMessage: responseData.data.message,
            }));

            
            return responseData.data.user
          } else {
            Logout();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    VerifyToken();
  }, []);

  const Login = (email, password) => {
    return LoginRequest(email, password)
      .then(responseData => {
        if (responseData.status === 200) {
          const token = responseData.data.token;
          localStorage.setItem('token', token);
        }
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  const Signup = (firstname, name, email, password) => {
    return SignupRequest(firstname, name, email, password)
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        throw error;
      });
  };

  const Logout = () => {
    localStorage.removeItem('token');
    const redirectProps = {
      status: 400,
      error: "Votre compte a été déconnecté",
      message: null,
    };

    navigate('/redirect', { state: redirectProps });


    setTimeout(() => {
      navigate('/home');
    }, 3000);

  };

  return (
    <AuthContext.Provider
      value={{ Signup, Login, Logout, VerifyToken}}
    >
      {props.children}

      <Snackbar
        open={state.showAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <MuiAlert
          onClose={handleAlertClose}
          severity={state.alertSeverity}
          sx={{ width: '200px' }}
        >
          {state.alertMessage}
        </MuiAlert>
      </Snackbar>
    </AuthContext.Provider>
  );
}

export { AuthController, AuthContext };
