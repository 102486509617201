import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import '../../styles/others/ProjectForm.css';
import ProjectFormController from '../../controllers/projects/ProjectFormController';
import ProjectFormRequest from '../../requests/projects/ProjectFormRequest';

// Loading
import LoadingComponent from "../../components/others/LoadingComponent"

import ForwardIcon from '@mui/icons-material/ArrowForward';
import BackwardIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

import { useNavigate } from 'react-router-dom';

const ProjectForm = () => {
  const [part, setPart] = useState(1);
  const [formData, setFormData] = useState({
    civilite: [],
    prenom: '',
    nom: '',
    telephone: '',
    email: '',
    secteur: '',
    codePostal: '',
    message: '',
    budget: 0,
    surfaceHabitable: 0,
    surfaceTerrain: 0,
    natureProjet: [],
    typeBien: [],
    recevoirEmail: false,
  });
  const [formErrors, setFormErrors] = useState({
    prenom: '',
    nom: '',
    telephone: '',
    email: '',
    secteur: '',
    codePostal: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const { natureProjet, typeBien, civilite } = formData;

    let updatedValue;
    if (name === 'natureProjet') {
      updatedValue = checked ? [...natureProjet, value] : natureProjet.filter(item => item !== value);
      setFormData(prevState => ({
        ...prevState,
        natureProjet: updatedValue,
      }));
    } 
    else if (name === 'typeBien') 
    {
      updatedValue = checked ? [...typeBien, value] : typeBien.filter(item => item !== value);
      setFormData(prevState => ({
        ...prevState,
        typeBien: updatedValue,
      }));
    }
    else if (name === 'civilite') 
    {
      updatedValue = checked ? value : '';
      setFormData(prevState => ({
        ...prevState,
        civilite: updatedValue,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let updatedValue;
    if (type === 'checkbox') {
      updatedValue = checked;
    } else {
      updatedValue = value;
    }
    setFormData(prevState => ({
      ...prevState,
      [name]: updatedValue,
    }));
    validateFormField(name, updatedValue);
  };
  
  const validateFormField = (fieldName, value) => {
    const projectFormController = new ProjectFormController();
    let errorMessage = '';

    switch (fieldName) {
      case 'prenom':
        errorMessage = projectFormController.validatePrenom(value);
        break;
      case 'nom':
        errorMessage = projectFormController.validateNom(value);
        break;
      case 'email':
        errorMessage = projectFormController.validateEmail(value);
        break;
      case 'telephone':
        errorMessage = projectFormController.validateTelephone(value);
        break;
      case 'secteur':
        errorMessage = projectFormController.validateSecteur(value);
        break;
      case 'codePostal':
        errorMessage = projectFormController.validateCodePostal(value);
        break;
      case 'message':
        errorMessage = projectFormController.validateMessage(value);
        break;
      default:
        break;
    }

    setFormErrors(prevState => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { civilite, prenom, nom, telephone, email, secteur, codePostal, message, budget, surfaceHabitable, surfaceTerrain, natureProjet, typeBien, recevoirEmail } = formData;

    setIsLoading(true); 

    ProjectFormRequest(civilite, prenom, nom, telephone, email, secteur, codePostal, message, budget, surfaceHabitable, surfaceTerrain, natureProjet, typeBien, recevoirEmail)
      .then(responseData => {

        setIsLoading(false); 

        if (responseData.status && (responseData.data.error || responseData.data.message)) {
          const redirectProps = {
            status: responseData.status,
            error: responseData.data.error,
            message: responseData.data.message,
          };
          navigate('/redirect', { state: redirectProps });

          setTimeout(() => {
            // window.history.back()
          }, 5000);
        }

        
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false); 
      });
  };

  const goToNextPart = () => {
    if (part === 1) {
      const { civilite, nom, prenom, telephone, email } = formData;
      const hasErrors = Object.values(formErrors).some(error => error !== '');

      if (civilite && nom && prenom && telephone && email && !hasErrors) {
        setPart(prevPart => prevPart + 1);
      } else {
        console.log('Veuillez remplir tous les champs requis.');
      }
    } else if (part === 2) {
      const { natureProjet, typeBien, secteur, codePostal, budget, surfaceHabitable, surfaceTerrain } = formData;
      const hasErrors = Object.values(formErrors).some(error => error !== '');

      if (natureProjet.length > 0 && typeBien.length > 0 && secteur && codePostal && budget && surfaceHabitable && surfaceTerrain && !hasErrors) {
        setPart(prevPart => prevPart + 1);
      } else {
        console.log('Veuillez remplir tous les champs requis.');
      }
    }
  };

  const goToPreviousPart = () => {
    setPart(prevPart => prevPart - 1);
  };

  return  (
    <>
      {isLoading ? (
        <LoadingComponent />
      )   
      : 
      (
      <>
      {part === 1 && (
        <div className="form-container">
          <h1>MES INFOS</h1>
          <p className="form-part">{part} / 3</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group" style={{ marginBottom: '40px' }}>
              <label>Civilité : </label>
              <br></br>
              <Checkbox name="civilite" value="Monsieur" checked={formData.civilite === 'Monsieur'} onChange={handleCheckboxChange} /> Monsieur
              <Checkbox name="civilite" value="Madame" checked={formData.civilite === 'Madame'} onChange={handleCheckboxChange} /> Madame
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Prénom" name="prenom" value={formData.prenom} onChange={handleInputChange} required error={!!formErrors.prenom} helperText={formErrors.prenom} inputProps={{ maxLength: 50 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Nom" name="nom" value={formData.nom} onChange={handleInputChange} required error={!!formErrors.nom} helperText={formErrors.nom} inputProps={{ maxLength: 50 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Téléphone portable" name="telephone" value={formData.telephone} onChange={handleInputChange} required error={!!formErrors.telephone} helperText={formErrors.telephone} inputProps={{ maxLength: 50 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Adresse email" type="email" name="email" value={formData.email} onChange={handleInputChange} required error={!!formErrors.email} helperText={formErrors.email} inputProps={{ maxLength: 50 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="button-container">
                <Button variant="contained" className="gold-button" size="small" onClick={goToNextPart}>
                  Suivant <ForwardIcon/>
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {part === 2 && (
        <div className="form-container">
          <h1>MON BIEN</h1>
          <p className="form-part">{part} / 3</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group" style={{ marginBottom: '40px' }}>
              <label>Nature du projet : </label>
              <br></br>
              <Checkbox name="natureProjet" value="Achat" checked={formData.natureProjet.includes('Achat')} onChange={handleCheckboxChange} /> Achat
              <Checkbox name="natureProjet" value="Vente" checked={formData.natureProjet.includes('Vente')} onChange={handleCheckboxChange} /> Vente
              <Checkbox name="natureProjet" value="Construction" checked={formData.natureProjet.includes('Construction')} onChange={handleCheckboxChange} /> Construction
              <Checkbox name="natureProjet" value="Rénovation" checked={formData.natureProjet.includes('Rénovation')} onChange={handleCheckboxChange} /> Rénovation
            </div>
            <div className="form-group" style={{ marginBottom: '40px' }}>
              <label>Type de bien : </label>
              <br></br>
              <Checkbox name="typeBien" value="Maison" checked={formData.typeBien.includes('Maison')} onChange={handleCheckboxChange} /> Maison
              <Checkbox name="typeBien" value="Terrain" checked={formData.typeBien.includes('Terrain')} onChange={handleCheckboxChange} /> Terrain
              <Checkbox name="typeBien" value="Appartement" checked={formData.typeBien.includes('Appartement')} onChange={handleCheckboxChange} /> Appartement
              <Checkbox name="typeBien" value="Commerce" checked={formData.typeBien.includes('Commerce')} onChange={handleCheckboxChange} /> Commerce
              <Checkbox name="typeBien" value="Bureaux" checked={formData.typeBien.includes('Bureaux')} onChange={handleCheckboxChange} /> Bureaux
              <Checkbox name="typeBien" value="Parking" checked={formData.typeBien.includes('Parking')} onChange={handleCheckboxChange} /> Parking
              <Checkbox name="typeBien" value="Autre" checked={formData.typeBien.includes('Autre')} onChange={handleCheckboxChange} /> Autre
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Secteur" name="secteur" value={formData.secteur} onChange={handleInputChange} required error={!!formErrors.secteur} helperText={formErrors.secteur} inputProps={{ maxLength: 50 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Code Postal" name="codePostal" value={formData.codePostal} onChange={handleInputChange} required error={!!formErrors.codePostal} helperText={formErrors.codePostal} inputProps={{ maxLength: 50 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Message (obligatoire)" name="message" value={formData.message} onChange={handleInputChange} required multiline error={!!formErrors.message} helperText={formErrors.message} inputProps={{ maxLength: 500 }} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Budget" name="budget" value={formData.budget} onChange={handleInputChange} type="number" required />
                <Slider value={formData.budget} onChange={(e, value) => handleInputChange({ target: { name: 'budget', value, type: 'slider', checked: false } })} min={0} max={1000000} step={100} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Surface habitable" name="surfaceHabitable" value={formData.surfaceHabitable} onChange={handleInputChange} type="number" required />
                <Slider value={formData.surfaceHabitable} onChange={(e, value) => handleInputChange({ target: { name: 'surfaceHabitable', value, type: 'slider', checked: false } })} min={0} max={1000} step={1} />
              </div>
            </div>
            <div className="form-group">
              <div className="input-container">
                <TextField label="Surface terrain" name="surfaceTerrain" value={formData.surfaceTerrain} onChange={handleInputChange} type="number" required />
                <Slider value={formData.surfaceTerrain} onChange={(e, value) => handleInputChange({ target: { name: 'surfaceTerrain', value, type: 'slider', checked: false } })} min={0} max={10000} step={1} />
              </div>
            </div>
            <div className="form-group">
              <div className="button-container">
                <Button variant="contained" className="gold-button" size="small" onClick={goToPreviousPart}><BackwardIcon/> Précédent</Button>
                <Button variant="contained" className="gold-button" size="small" onClick={goToNextPart}>Suivant <ForwardIcon/></Button>
              </div>
            </div>
          </form>
        </div>
      )}

      {part === 3 && (
        <div className="form-container">
          <h1>CONFIRMER</h1>
          <p className="form-part">{part} / 3</p>
          <form onSubmit={handleSubmit}>
            <h3>Récapitulatif des données saisies :</h3>
            <br></br>
            <ul>
              <li>Civilité : {formData.civilite}</li>
              <li>Nom : {formData.nom}</li>
              <li>Prénom : {formData.prenom}</li>
              <li>Téléphone : {formData.telephone}</li>
              <li>Email : {formData.email}</li>
              <li>Secteur : {formData.secteur}</li>
              <li>Code Postal : {formData.codePostal}</li>
            </ul>
            <br></br>
            <div className="form-group">
              <label>Recevoir des emails de la part du propriétaire du site :</label>
              <Checkbox name="recevoirEmail" checked={formData.recevoirEmail} onChange={handleInputChange} />
            </div>
            <br></br>
            <div className="form-group">
              <div className="button-container">
                <Button variant="contained" className="gold-button" size="small" onClick={goToPreviousPart}>
                  <BackwardIcon/> Précédent 
                </Button>
                <Button variant="contained" className="gold-button" size="small" type="submit">
                  Confirmer <CheckIcon/>
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
      </>
    )}
    </>
  );
};

export default ProjectForm;
