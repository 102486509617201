const VerifyTokenRequest = async (token) => {
    const url = `https://api.chapron-immo.fr/users/verify-token`;
    
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
  
    return fetch(url, requestOptions)
        .then(response => response.json().then(data => ({ data, status: response.status })))
        .then(responseData => {
            return responseData; // Vous pouvez retourner les données si nécessaire
            
        })
        .catch(error => {
            // console.error('Erreur de la requête:', error);
            throw error; // Vous pouvez jeter l'erreur si nécessaire
        });
};
  
export default VerifyTokenRequest;
  