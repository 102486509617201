import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MuiAlert from '@mui/material/Alert';
import GetFavoritesRequest from "../../requests/favorites/GetFavoritesRequest";
import AddFavoriteRequest from "../../requests/favorites/AddFavoriteRequest";
import RemoveFavoriteRequest from "../../requests/favorites/RemoveFavoriteRequest";

import { AuthContext } from "../../controllers/users/AuthController";

import "../../styles/properties/PropertiesMapComponent.css";

class PropertiesMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      favorites: [],
      alertOpen: false,
      alertSeverity: '',
      alertMessage: '',
      user: null,
    };
    localStorage.removeItem('hasReloaded');
  }

  componentDidMount() {
    const { VerifyToken } = this.context;
    const token = localStorage.getItem('token');

    if (token) {
      VerifyToken(token)
        .then(responseData => {
          this.setState({ user: responseData }, () => {
            console.log('User state:', this.state.user);
            this.getFavorites();
          });
        })
        .catch(error => {
          console.error('Error verifying token:', error);
        });
    }
  }

  getFavorites = () => {
    const { user } = this.state;

    if (user) {
      GetFavoritesRequest(user.userId)
        .then((response) => {
          this.setState({ favorites: response.data.favorites }, () => {
            console.log('Favorites state:', this.state.favorites);
          });
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des favoris :', error);
        });
    }
  };

  toggleFavorite = (propertyId) => {
    const { favorites, user } = this.state;
  
    if (!user) {
      this.showAlert('error', 'Vous devez vous connecter');
      return;
    }
  
    const isFavorite = favorites.some((favorite) => favorite.propertyId === propertyId);
  
    if (isFavorite) {
      RemoveFavoriteRequest(this.state.user.userId, propertyId)
        .then(() => {
          this.setState((prevState) => ({
            favorites: prevState.favorites.filter((favorite) => favorite.propertyId !== propertyId),
          }));
          this.showAlert('success', 'La propriété a été supprimée des favoris avec succès.');
        })
        .catch((error) => {
          console.error('Erreur lors de la suppression du favori :', error);
          this.showAlert('error', 'Erreur lors de la suppression du favori.');
        });
    } else {
      AddFavoriteRequest(this.state.user.userId, propertyId)
        .then(() => {
          this.setState((prevState) => ({
            favorites: [...prevState.favorites, { propertyId }],
          }));
          this.showAlert('success', 'La propriété a été ajoutée aux favoris avec succès.');
        })
        .catch((error) => {
          console.error('Erreur lors de l\'ajout du favori :', error);
          this.showAlert('error', 'Erreur lors de l\'ajout du favori.');
        });
    }
  };
  

  showAlert = (severity, message) => {
    this.setState({
      alertOpen: true,
      alertSeverity: severity,
      alertMessage: message,
    });
  };

  handleAlertClose = () => {
    this.setState({
      alertOpen: false,
      alertSeverity: '',
      alertMessage: '',
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    const { properties } = this.props;



    if (!Array.isArray(properties) || properties.length === 0) {
      return null;
    }

    const firstProperty = properties[0];

    if (firstProperty && firstProperty.images) {
      // Convertir la chaîne JSON en tableau d'images
      const imagesArray = JSON.parse(firstProperty.images);

      // Vérifier si des images sont présentes et en prendre la première
      if (Array.isArray(imagesArray) && imagesArray.length > 0) {
        const firstImage = imagesArray[0];

        console.log("Première image de la première propriété :", firstImage);
      }
    }

    const { currentPage, favorites, alertOpen, alertSeverity, alertMessage } = this.state;
    const propertiesPerPage = 10;

    const startIndex = (currentPage - 1) * propertiesPerPage;
    const endIndex = startIndex + propertiesPerPage;

    const propertiesToDisplay = properties.slice(startIndex, endIndex);

    const totalPages = Math.ceil(properties.length / propertiesPerPage);

    const paginationItems = [];

    if (properties.length > 10) {
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        paginationItems.push(
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => this.handlePageChange(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
    }

    return (
      <div>
        <div className="properties-map-container">
          {propertiesToDisplay.map((property, index) => {
            const isFavorite = favorites && favorites.some((favorite) => favorite.propertyId === property.id);

            const imagesArrayParse = JSON.parse(property.images)

            return (
              <div key={index} className="property">
                <Carousel className="custom-carousel" interval={null} pause={true}>
                  {imagesArrayParse.map((imageUrl, index) => (
                    <Carousel.Item key={index}>
                      <div className="image-container">
                        <Link to={`/property/${property.id}`}>
                          <img src={imageUrl} alt="Property" />
                        </Link>

                        <button
                          className={`favorite-button ${isFavorite ? 'active' : ''}`}
                          onClick={() => this.toggleFavorite(property.id)}
                        >
                          {isFavorite ? (
                            <FavoriteIcon fontSize="large" />
                          ) : (
                            <FavoriteBorderIcon fontSize="large" />
                          )}
                        </button>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
                <div className="info">
                  <div className="first-info">
                    <div className="property-type">{property.type}</div>
                    <div className="price">{property.price} €</div>
                  </div>
                  <div className="location">{property.location}</div>
                  <div className="features">
                    <div className="feature">
                      <span className="feature-label">Surface :</span> {property.surfacePrimary} m²
                    </div>
                    {property.rooms !== undefined && property.rooms !== null && property.rooms !== 0 && (
                      <div className="feature">
                        <span className="feature-label">Pièces :</span> {property.rooms}
                      </div>
                    )}

                    {property.bedRooms !== undefined && property.bedRooms !== null && property.bedRooms !== 0 && property.bedRooms !== "0" && (
                      <div className="feature">
                        <span className="feature-label">Chambres :</span> {property.bedRooms}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination-container">
          <Pagination>{paginationItems}</Pagination>
        </div>

        {alertOpen && (
          <MuiAlert
            onClose={this.handleAlertClose}
            severity={alertSeverity}
            sx={{
              width: '30%',
              position: 'fixed',
              bottom: '10px',
              left: '10px',
            }}
            open={alertOpen}
          >
            {alertMessage}
          </MuiAlert>
        )}
      </div>
    );
  }
}

PropertiesMapComponent.contextType = AuthContext;

export default PropertiesMapComponent;
