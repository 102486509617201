import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../../styles/users/LoginPage.css';
import { AuthContext } from "../../controllers/users/AuthController";
import { useNavigate,Link } from 'react-router-dom';

// Loading 

import LoadingComponent from "../../components/others/LoadingComponent"


import { Helmet } from 'react-helmet';

const LoginPage = () => {
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
  });

  const { Login } = React.useContext(AuthContext);
  
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = formData;

    setIsLoading(true);

    Login(email, password)
      .then((responseData) => {
        setIsLoading(false);
        if (responseData.status && (responseData.data.error || responseData.data.message)) {
          const redirectProps = {
            status: responseData.status,
            error: responseData.data.error,
            message: responseData.data.message,
          };

          navigate('/redirect', { state: redirectProps });

          if (responseData.status == "200") {
            setTimeout(() => {
              navigate('/profile');
            }, 3000);
            
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) 

    : 
    (
    <>
    <Helmet>
        <title>Connexion - Chapron-Immo</title>
        <meta name="description" content="Connectez-vous à votre compte chez Monsieur Chapron Frédéric pour accéder à des fonctionnalités réservées aux membres." />
        <meta name="keywords" content="connexion, compte, Monsieur Chapron Frédéric" />
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Language" content="fr" />
    </Helmet>
    <div className="login-container">
      <form className="login-form-container" onSubmit={handleSubmit}>
        <h2>Me connecter</h2>
        <br />
        <div className="form-group input-container">
          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="input-field"
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div style={{ marginTop: '20px' }} className="form-group input-container">
          <TextField
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
            className="input-field"
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <br />
        <div className="form-group login-button-container">
          <Button variant="contained" className="gold-button" size="small" type="submit">
            Connexion
          </Button>
          <div style={{ marginTop: '20px', fontSize: '14px' }} className="form-group input-container">
            Vous n'avez pas de compte ? <Link to="/signup" style={{ color: 'white' }}>Inscrivez-vous</Link> !
          </div>
        </div>
      </form>
    </div>
    </>
  )}
  </>
  );
};

export default LoginPage;
