import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormControlLabel, FormControl, Select, MenuItem, TextField, Checkbox} from '@mui/material';
import SortingFormController from '../../controllers/properties/SortingFormController';
import { PropertiesContext } from '../../controllers/properties/PropertiesController';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import "../../styles/sorting/SortingComponent.css";

const SortingComponent = () => {
  const { GetSectors } = useContext(PropertiesContext);
  const [formData, setFormData] = useState({ secteur: '', budget: '', typeBien: [], minSurface: '' });
  const [sectorsList, setSectorsList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSectors();
  }, []);

  const fetchSectors = () => {
    GetSectors()
      .then(responseData => {
        setSectorsList(responseData.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const { typeBien } = formData;
    let updatedTypeBien;

    if (checked) updatedTypeBien = [...typeBien, value];
    else updatedTypeBien = typeBien.filter(item => item !== value);

    setFormData(prevState => ({
      ...prevState,
      typeBien: updatedTypeBien
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    validateFormField(name, value);
  };

  const validateFormField = (fieldName, value) => {
    const sortingFormController = new SortingFormController();
    let errorMessage = '';

    switch (fieldName) {
      case 'secteur':
        errorMessage = sortingFormController.validateSecteur(value);
        break;
      case 'budget':
        errorMessage = sortingFormController.validateBudget(value);
        break;
      case 'typeBien':
        errorMessage = sortingFormController.validateTypeBien(value);
        break;
      case 'minSurface':
        errorMessage = sortingFormController.validateMinSurface(value);
        break;
      default:
        break;
    }

    return errorMessage;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { secteur, budget, typeBien, minSurface } = formData;

  
    if (secteur && budget && typeBien && minSurface) {
      const queryString = `secteur=${secteur}&budget=${budget}&types=${typeBien.join(',')}&surface=${minSurface}`;
      navigate(`/achat/results?${queryString}`);
    }
  };

  return (
    <div className="sorting-container">
      <Row>
        <Col xs={6} sm={6} md={6} >
          <FormControl>
            <label>Secteur :</label>
            <Select name="secteur" className="custom-select" value={formData.secteur} onChange={handleInputChange}>
              <MenuItem value="Tous">
                Tous
              </MenuItem>
              {sectorsList.map((secteurItem, index) => (
                <MenuItem key={index} value={secteurItem}>
                  {secteurItem}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        </Col>
        <Col xs={6} sm={6} md={6} className="">
          <FormControl>
            <label>Budget Maximum :</label>
            <TextField name="budget" value={formData.budget} onChange={handleInputChange} label="" type="number" className="custom-textfield" />
          </FormControl>
        </Col>
        <Col xs={6} sm={6} md={6} className="">
          <div>
            <label className="checkbox-group" style={{ marginTop: '20px' }}>
              <div><Checkbox name="typeBien" value="Maison" onChange={handleCheckboxChange} />Maison  </div>
              <div>Terrain<Checkbox name="typeBien" value="Terrain" onChange={handleCheckboxChange} /> </div>
              <div><Checkbox name="typeBien" value="Autre" onChange={handleCheckboxChange} /> Autre</div>
            </label>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} className="">
          <FormControl>
            <label>Surface Minimum :</label>
            <TextField name="minSurface" value={formData.minSurface} onChange={handleInputChange} label="" type="number" className="custom-textfield" />
          </FormControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="search-button">
            <Button variant="contained" className="gold-button" size="small" onClick={handleSubmit}>
              Rechercher
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SortingComponent;
